import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import defaultMergeTags from 'json/merge-tags.json'
import { fetchMergeTags, selectSavedContents } from 'slices/savedContents'
import { selectSavedContent } from 'slices/savedContent'
import { slugify } from 'utils'
import { beeTypes } from '@mailupinc/bee-plugin'

export const useMergeCodes = () => {
  const dispatch = useAppDispatch()
  const {
    mergeTags: savedContents,
    error,
    loading
  } = useAppSelector(selectSavedContents)
  const { data: savedContent } = useAppSelector(selectSavedContent)
  const currentId = savedContent?.campaign_content_id

  const avaialabeContent = useMemo(() => {
    return (
      savedContents?.filter(snippet => {
        // Filter out archived content
        if (snippet.is_archived) return false
        // Filter out the current content
        return snippet.campaign_content_id !== currentId
      }) || []
    )
  }, [savedContents, currentId])

  useEffect(() => {
    dispatch(
      fetchMergeTags({
        'sort-by': 'title'
      })
    )
  }, [dispatch])

  const mergeTags = useMemo(() => {
    return avaialabeContent
      ? [
          ...defaultMergeTags,
          ...avaialabeContent
            .filter(content => {
              // Filter merge tags
              if (content.content_type !== 'TEXT') return false
              return true
            })
            .map(snippet => {
              return {
                name: snippet.subject || snippet.title || snippet.preview_text,
                value: `{{saved[ ${snippet.campaign_content_id}]}}`,
                previewValue:
                  snippet.preview_text || snippet.subject || snippet.title
              }
            })
        ]
      : defaultMergeTags
  }, [avaialabeContent])

  const specialLinks = useMemo(() => {
    return avaialabeContent
      ?.filter(content => {
        // Filter Saved URLs
        if (content.content_type !== 'LINK') return false
        return true
      })
      .map(url => ({
        type: url.subject || 'Special Links',
        label: url.preview_text || url.title || 'Special Link',
        link: url.body
      }))
  }, [avaialabeContent])

  const getRows = useCallback(
    async (
      resolve: (data: beeTypes.IPluginRow[]) => void,
      reject: () => void,
      args: any
    ) => {
      const matchingSavedRows =
        avaialabeContent?.filter(content => {
          // Filter Saved URLs
          if (content.content_type !== 'ROW') return false
          if (slugify(content.subject || 'Saved Rows') !== args['handle'])
            return false
          return true
        }) || []
      const savedRowConfig = matchingSavedRows.reduce((agg, row) => {
        const rowConfig =
          JSON.parse(row.editor_config || '{}')?.page?.rows?.[0] || null
        if (!rowConfig) return agg
        return [
          ...agg,
          {
            ...rowConfig,
            metadata: {
              name: row.title,
              description: row.description,
              id: row.campaign_content_id
            }
          } as beeTypes.IPluginRow
        ]
      }, [] as beeTypes.IPluginRow[])
      resolve(savedRowConfig)
    },
    [avaialabeContent]
  )

  const externalContentURLs = useMemo(() => {
    const categories = avaialabeContent
      ?.filter(content => {
        // Filter Saved URLs
        if (content.content_type !== 'ROW') return false
        return true
      })
      .reduce((map, row) => {
        map.set(
          slugify(row.subject || 'Saved Rows'),
          row.subject || 'Saved Rows'
        )
        return map
      }, new Map<string, string>())

    return Array.from(categories, ([handle, name]) => ({
      handle,
      name,
      value: name,
      isLocal: true
    }))
  }, [avaialabeContent])

  return {
    error,
    loading,
    mergeTags,
    specialLinks,
    externalContentURLs,
    getRows
  }
}
export default useMergeCodes
