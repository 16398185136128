import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { WanConfig } from 'types'
import { showNotification } from './notification'
import { NavigateFunction } from 'react-router-dom'

export interface WanConfigsState {
  data: Array<WanConfig>
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface WanConfigsAPI {
  data: Array<WanConfig>
  links: {
    next: string
  }
}

export type WanConfigsParams = {
  pos_server_id?: number
  limit?: number
  cursor?: string
}

const initialState: WanConfigsState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchWanConfigs = createAsyncThunk<
  WanConfigsAPI,
  WanConfigsParams | void,
  { state: RootState; rejectValue: RequestError }
>(
  'wanConfigs/fetchWanConfigs',
  async (params, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const queryParams = params ? makeQueryParams(params) : ''
      return (await api?.request(
        `pos-v2/wan-configs${queryParams}`
      )) as WanConfigsAPI
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const createWanConfigs = createAsyncThunk<
  WanConfig,
  { data: WanConfig; navigate: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'wanConfigs/createWanConfigs',
  async (
    { data /* , navigate */ },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/wan-configs',
        'POST',
        data
      )) as WanConfig
      dispatch(showNotification('Wan Config successfully created!'))
      dispatch(fetchWanConfigs())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const wanConfigsSlice = createSlice({
  name: 'wanConfigs',
  initialState,
  reducers: {
    resetWanConfigs: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchWanConfigs.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchWanConfigs.fulfilled, (state, { payload }) => {
      state.data = payload.data
      state.links = payload.links
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchWanConfigs.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetWanConfigs } = wanConfigsSlice.actions

export const selectWanConfigs = (state: RootState) => state.wanConfigs
export const selectWanConfigsData = (state: RootState) => state.wanConfigs.data
export const selectWanConfigsLinks = (state: RootState) =>
  state.wanConfigs.links
export const selectWanConfigsLoading = (state: RootState) =>
  state.wanConfigs.loading

export default wanConfigsSlice.reducer
