import { combineReducers } from '@reduxjs/toolkit'

import allergenReducer from './allergen'
import allergensReducer from './allergens'
import baseDeliveryFeeReducer from './baseDeliveryFee'
import baseDeliveryFeesReducer from './baseDeliveryFees'
import brandReducer from './brand'
import brandsReducer from './brands'
import builtMenuReducer from './builtMenu'
import bulkOrderReducer from './bulkOrder'
import bulkOrdersReducer from './bulkOrders'
import announcementReducer from './announcement'
import announcementsReducer from './announcements'
import announcementPageReducer from './announcementPage'
import announcementPagesReducer from './announcementPages'
import appComponentsReducer from './appComponents'
import appConfigReducer from './appConfig'
import appContentReducer from './appContent'
import appPageReducer from './appPage'
import appSystemReducer from './appSystem'
import appThemeReducer from './appTheme'
import appVariablesReducer from './appVariables'
import attributesReducer from './attributes'
import authReducer from './auth'
import authUserReducer from './authUser'
import backgroundTasksReducer from './backgroundTasks'
import blockedEmailReducer from './blockedEmail'
import blockedEmailsReducer from './blockedEmails'
import blockedPhoneReducer from './blockedPhone'
import blockedPhonesReducer from './blockedPhones'
import brandByLocationReducer from './brandByLocation'
import campaignContentReducer from './campaignContent'
import campaignContentTokenReducer from './campaignContentToken'
import campaignRecipientsReducer from './campaignRecipients'
import campaignReducer from './campaign'
import campaignsReducer from './campaigns'
import categoriesReducer from './categories'
import categoryReducer from './category'
import ccpaCustomersReducer from './ccpaCustomers'
import checkmateReducer from './checkmate'
import confirmationEmailReducer from './confirmationEmail'
import confirmationEmailsReducer from './confirmationEmails'
import createBrandReducer from './createBrand'
import curbsidePickupReducer from './curbsidePickup'
import customerReducer from './customer'
import customerAddressReducer from './customerAddress'
import customerCardReducer from './customerCard'
import customerNoteReducer from './customerNote'
import customerOrdersReducer from './customerOrders'
import customersReducer from './customers'
import creditCardSummaryReducer from './creditCardSummaryReports'
import deliveryZoneReducer from './deliveryZone'
import discountEmailReducer from './discountEmail'
import discountReducer from './discount'
import discountsReducer from './discounts'
import discountRevenueCentersReducer from './discountRevenueCenters'
import distanceDeliveryTiersReducer from './distanceDeliveryTiers'
import doordashReducer from './doordash'
import editorReducer from './editor'
import emailDesignReducer from './emailDesign'
import emailRecipientsReducer from './emailRecipients'
import externalUsersReducer from './iacm/externalUsers'
import filesReducer from './files'
import firebaseReducer from './firebase'
import fontReducer from './font'
import fontsReducer from './fonts'
import giftCardReducer from './giftCard'
import giftCardsReducer from './giftCards'
import giftCardBatchReducer from './giftCardBatch'
import giftCardBatchesReducer from './giftCardBatches'
import globalReducer from './global'
import hoursReducer from './hours'
import houseAccountReducer from './houseAccount'
import houseAccountCustomerReducer from './houseAccountCustomer'
import houseAccountInvoiceReducer from './houseAccountInvoice'
import houseAccountInvoicesReducer from './houseAccountInvoices'
import houseAccountNoteReducer from './houseAccountNote'
import houseAccountNotesReducer from './houseAccountNotes'
import houseAccountOrdersReducer from './houseAccountOrders'
import houseAccountRevenueCentersReducer from './houseAccountRevenueCenters'
import houseAccountsReducer from './houseAccounts'
import iacmUserReducer from './iacmUser'
import imageSizesReducer from './imageSizes'
import invoiceSettingReducer from './invoiceSetting'
import itemAllergensReducer from './itemAllergens'
import itemLoyaltyTierReducer from './itemLoyaltyTier'
import itemReducer from './item'
import itemsReducer from './items'
import itemModifierGroupsReducer from './itemModifierGroups'
import itemLoyaltyBonusReducer from './itemLoyaltyBonus'
import itemTypeReducer from './itemType'
import itemTypesReducer from './itemTypes'
import itemTagsReducer from './itemTags'
import kdsTerminalPrepStationsReducer from './kdsTerminalPrepStations'
import kdsTerminalReducer from './kdsTerminal'
import kdsTerminalsReducer from './kdsTerminals'
import loyaltyProgramReducer from './loyaltyProgram'
import loyaltyProgramsReducer from './loyaltyPrograms'
import loyaltyTierReducer from './loyaltyTier'
import loyaltyTiersReducer from './loyaltyTiers'
import loyaltyTierItemReducer from './loyaltyTierItems'
import loyaltyBonusReducer from './loyaltyBonus'
import loyaltyBonusItemReducer from './loyaltyBonusItems'
import loyaltyBonusesReducer from './loyaltyBonuses'
import loyaltyCustomersReducer from './loyaltyCustomers'
import loyaltyThresholdReducer from './loyaltyThreshold'
import loyaltyThresholdsReducer from './loyaltyThresholds'
import loyaltyDiscountsReducer from './loyaltyDiscounts'
import loyaltyTierRewardReducer from './loyaltyTierRewards'
import marketingPushReducer from './marketingPush'
import marketingSettingsReducer from './marketingSettings'
import massCustomersReducer from './massCustomers'
import menuReducer from './menu'
import menusReducer from './menus'
import modalReducer from './modal'
import modifierGroupModifiersReducer from './modifiers'
import modifierGroupReducer from './modifierGroup'
import modifierGroupsReducer from './modifierGroups'
import newsletterReducer from './newsletter'
import notificationReducer from './notification'
import notificationPreferenceReducer from './notificationPreference'
import notificationPreferencesReducer from './notificationPreferences'
import nutritionalInfoReducer from './nutritionalInfo'
import oauth2ClientReducer from './oauth2Client'
import oauth2ClientsReducer from './oauth2Clients'
import onboardingAllLocationsReducer from './iacm/onboardingAllLocations'
import onboardingBrandReducer from './iacm/onboardingBrand'
import onboardingBrandsCorporateOnly from './iacm/onboardingBrandsCorporateOnly'
import onboardingBrandsReducer from './iacm/onboardingBrands'
import onboardingLocationReducer from './iacm/onboardingLocation'
import onboardingMenuMappingReducer from './iacm/onboardingMenuMapping'
import onboardingOrderingPlatformReducer from './iacm/onboardingOrderingPlatform'
import onboardingOrderingPlatformsReducer from './iacm/onboardingOrderingPlatforms'
import orderingPlatformsReducer from './iacm/orderingPlatforms'
import orderRatingReducer from './orderRating'
import orderRatingsReportReducer from './orderRatingsReport'
import orderReducer from './order'
import orderMgmtReducer from './orderMgmt'
import orderNotificationReducer from './orderNotification'
import orderNotificationsReducer from './orderNotifications'
import orderRemainingRefundReducer from './orderRefund'
import partialRefundReducer from './partialRefund'
import paymentsReducer from './payments'
import paymentTerminalReducer from './paymentTerminal'
import paymentTerminalsReducer from './paymentTerminals'
import posReducer from './pos'
import posIdentityRequestReducer from './posIdentityRequest'
import posIdentityRequestsReducer from './posIdentityRequests'
import posServerReducer from './posServer'
import posServersReducer from './posServers'
import posTerminalReducer from './posTerminal'
import posTerminalsReducer from './posTerminals'
import posTypesReducer from './iacm/posTypes'
import posUpdateReducer from './posUpdate'
import prepStationReducer from './prepStation'
import prepStationItemTypesReducer from './prepStationItemTypes'
import prepStationsReducer from './prepStations'
import printerReducer from './printer'
import printersReducer from './printers'
import productMixReportReducer from './productMixReport'
import qrCodeReducer from './qrCode'
import onboardingQualityControlReportsReducer from './iacm/onboardingQualityControlReports'
import receiptNote from './receiptNote'
import receiptNotes from './receiptNotes'
import receiptsReportReducer from './receiptsReport'
import recommendedItemsReducer from './recommendedItems'
import regionsReducer from './regions'
import regionReducer from './region'
import regenerateReducer from './regenerate'
import releaseRedisLockReducer from './releaseRedisLock'
import relayReducer from './relay'
import revenueCenterReducer from './revenueCenter'
import revenueCenterBlockedHoursReducer from './revenueCenterBlockedHours'
import revenueCenterCreditReducer from './revenueCenterCredit'
import revenueCenterDeliveryReducer from './revenueCenterDelivery'
import revenueCenterEmailReducer from './revenueCenterEmail'
import revenueCenterGratuityReducer from './revenueCenterGratuity'
import revenueCenterOrderWindowsReducer from './revenueCenterOrderWindows'
import revenueCenterPosReducer from './revenueCenterPos'
import revenueCenterPosSettingsReducer from './revenueCenterPosSettings'
import revenueCenterServiceTypesReducer from './revenueCenterServiceTypes'
import revenueCentersReducer from './revenueCenters'
import revenueCenterSurchargesReducer from './revenueCenterSurcharges'
import revenueCenterUsersReducer from './revenueCenterUsers'
import savedContentReducer from './savedContent'
import savedContentsReducer from './savedContents'
import searchUserLocationsReducer from './iacm/searchUserLocations'
import scannerReducer from './scanner'
import scannersReducer from './scanners'
import segmentReducer from './segment'
import segmentsReducer from './segments'
import segmentRecipientsReducer from './segmentRecipients'
import storesReducer from './stores'
import storeReducer from './store'
import storeAddressReducer from './storeAddress'
import storeCreditReducer from './storeCredit'
import storeDeliveryReducer from './storeDelivery'
import storeEmailReducer from './storeEmail'
import storeGratuityReducer from './storeGratuity'
import storeGroupsReducer from './storeGroups'
import storeGroupReducer from './storeGroup'
import storeIntegrationReducer from './storeIntegration'
import storeMenusReducer from './storeMenus'
import storePosReducer from './storePos'
import storePosSettingsReducer from './storePosSettings'
import storeThrottleReducer from './storeThrottle'
import stylesheetsReducer from './stylesheets'
import surchargeReducer from './surcharge'
import surchargesReducer from './surcharges'
import tagReducer from './tag'
import tagsReducer from './tags'
import taxReducer from './tax'
import taxesReducer from './taxes'
import taxRevenueCentersReducer from './taxRevenueCenters'
import tendersReportReducer from './tendersReport'
import tendersSummaryReportReducer from './tendersSummaryReport'
import tenderTypesReducer from './tenderTypes'
import triggers from './triggers'
import twilioReducer from './twilio'
import uberDirectReducer from './uberDirect'
import unchargedOrdersReducer from './unchargedOrders'
import upcomingOrdersReducer from './upcomingOrders'
import userReducer from './user'
import userExperienceSettingsReducer from './userExperienceSettings'
import userPermissionsReducer from './userPermissions'
import userVpnConfigReducer from './userVpnConfig'
import usersReducer from './users'
import valutecReducer from './valutec'
import waitTimeDeliveryReducer from './waitTimeDelivery'
import waitTimePickupReducer from './waitTimePickup'
import waitTimesDeliveryReducer from './waitTimesDelivery'
import waitTimesPickupReducer from './waitTimesPickup'
import websiteReducer from './website'
import storeBaseDeliveryFeesReducer from './storeBaseDeliveryFees'
import storeDistanceDeliveryTiersReducer from './storeDistanceDeliveryTiers'
import wanConfigReducer from './wanConfig'
import wanConfigsReducer from './wanConfigs'

const rootReducer = combineReducers({
  allergen: allergenReducer,
  allergens: allergensReducer,
  baseDeliveryFee: baseDeliveryFeeReducer,
  baseDeliveryFees: baseDeliveryFeesReducer,
  storeBaseDeliveryFees: storeBaseDeliveryFeesReducer,
  storeDistanceDeliveryTiers: storeDistanceDeliveryTiersReducer,
  brand: brandReducer,
  brands: brandsReducer,
  builtMenu: builtMenuReducer,
  bulkOrders: bulkOrdersReducer,
  announcement: announcementReducer,
  announcements: announcementsReducer,
  announcementPage: announcementPageReducer,
  announcementPages: announcementPagesReducer,
  appComponents: appComponentsReducer,
  appConfig: appConfigReducer,
  appContent: appContentReducer,
  appPage: appPageReducer,
  appSystem: appSystemReducer,
  appTheme: appThemeReducer,
  appVariables: appVariablesReducer,
  attributes: attributesReducer,
  auth: authReducer,
  authUser: authUserReducer,
  backgroundTasks: backgroundTasksReducer,
  blockedEmail: blockedEmailReducer,
  blockedEmails: blockedEmailsReducer,
  blockedPhone: blockedPhoneReducer,
  blockedPhones: blockedPhonesReducer,
  brandByLocation: brandByLocationReducer,
  bulkOrder: bulkOrderReducer,
  campaign: campaignReducer,
  campaigns: campaignsReducer,
  campaignContent: campaignContentReducer,
  campaignContentToken: campaignContentTokenReducer,
  campaignRecipients: campaignRecipientsReducer,
  categories: categoriesReducer,
  category: categoryReducer,
  ccpaCustomers: ccpaCustomersReducer,
  checkmate: checkmateReducer,
  confirmationEmail: confirmationEmailReducer,
  confirmationEmails: confirmationEmailsReducer,
  createBrand: createBrandReducer,
  curbsidePickup: curbsidePickupReducer,
  customer: customerReducer,
  customerCard: customerCardReducer,
  customerAddress: customerAddressReducer,
  customerNote: customerNoteReducer,
  customerOrders: customerOrdersReducer,
  customers: customersReducer,
  creditCardSummaryReport: creditCardSummaryReducer,
  deliveryZone: deliveryZoneReducer,
  discountEmail: discountEmailReducer,
  discount: discountReducer,
  discounts: discountsReducer,
  discountRevenueCenters: discountRevenueCentersReducer,
  distanceDeliveryTiers: distanceDeliveryTiersReducer,
  doordash: doordashReducer,
  editor: editorReducer,
  emailDesign: emailDesignReducer,
  emailRecipients: emailRecipientsReducer,
  externalUsers: externalUsersReducer,
  font: fontReducer,
  fonts: fontsReducer,
  files: filesReducer,
  firebase: firebaseReducer,
  giftCard: giftCardReducer,
  giftCards: giftCardsReducer,
  giftCardBatch: giftCardBatchReducer,
  giftCardBatches: giftCardBatchesReducer,
  global: globalReducer,
  hours: hoursReducer,
  houseAccount: houseAccountReducer,
  houseAccountCustomer: houseAccountCustomerReducer,
  houseAccountInvoice: houseAccountInvoiceReducer,
  houseAccountInvoices: houseAccountInvoicesReducer,
  houseAccountNote: houseAccountNoteReducer,
  houseAccountNotes: houseAccountNotesReducer,
  houseAccountOrders: houseAccountOrdersReducer,
  houseAccountRevenueCenters: houseAccountRevenueCentersReducer,
  houseAccounts: houseAccountsReducer,
  iacmUser: iacmUserReducer,
  imageSizes: imageSizesReducer,
  invoiceSetting: invoiceSettingReducer,
  item: itemReducer,
  items: itemsReducer,
  itemAllergens: itemAllergensReducer,
  itemLoyaltyTier: itemLoyaltyTierReducer,
  itemModifierGroups: itemModifierGroupsReducer,
  itemLoyaltyBonus: itemLoyaltyBonusReducer,
  itemType: itemTypeReducer,
  itemTypes: itemTypesReducer,
  itemTags: itemTagsReducer,
  kdsTerminalPrepStations: kdsTerminalPrepStationsReducer,
  kdsTerminal: kdsTerminalReducer,
  kdsTerminals: kdsTerminalsReducer,
  loyaltyProgram: loyaltyProgramReducer,
  loyaltyPrograms: loyaltyProgramsReducer,
  loyaltyTier: loyaltyTierReducer,
  loyaltyTiers: loyaltyTiersReducer,
  loyaltyTierItem: loyaltyTierItemReducer,
  loyaltyBonus: loyaltyBonusReducer,
  loyaltyBonusItem: loyaltyBonusItemReducer,
  loyaltyBonuses: loyaltyBonusesReducer,
  loyaltyCustomers: loyaltyCustomersReducer,
  loyaltyThreshold: loyaltyThresholdReducer,
  loyaltyThresholds: loyaltyThresholdsReducer,
  loyaltyDiscounts: loyaltyDiscountsReducer,
  loyaltyTierReward: loyaltyTierRewardReducer,
  marketingSettings: marketingSettingsReducer,
  marketingPush: marketingPushReducer,
  massCustomers: massCustomersReducer,
  modal: modalReducer,
  modifierGroupModifiers: modifierGroupModifiersReducer,
  modifierGroup: modifierGroupReducer,
  modifierGroups: modifierGroupsReducer,
  menus: menusReducer,
  menu: menuReducer,
  notificationPreference: notificationPreferenceReducer,
  notificationPreferences: notificationPreferencesReducer,
  nutritionalInfo: nutritionalInfoReducer,
  newsletter: newsletterReducer,
  notifications: notificationReducer,
  oauth2Client: oauth2ClientReducer,
  oauth2Clients: oauth2ClientsReducer,
  onboardingAllLocations: onboardingAllLocationsReducer,
  onboardingBrand: onboardingBrandReducer,
  onboardingBrands: onboardingBrandsReducer,
  onboardingBrandsCorporate: onboardingBrandsCorporateOnly,
  onboardingLocation: onboardingLocationReducer,
  onboardingMenuMapping: onboardingMenuMappingReducer,
  onboardingOrderingPlatform: onboardingOrderingPlatformReducer,
  onboardingOrderingPlatforms: onboardingOrderingPlatformsReducer,
  order: orderReducer,
  orderingPlatforms: orderingPlatformsReducer,
  orderMgmt: orderMgmtReducer,
  orderNotification: orderNotificationReducer,
  orderNotifications: orderNotificationsReducer,
  orderRating: orderRatingReducer,
  orderRemainingRefund: orderRemainingRefundReducer,
  partialRefund: partialRefundReducer,
  orderRatingsReport: orderRatingsReportReducer,
  payments: paymentsReducer,
  paymentTerminal: paymentTerminalReducer,
  paymentTerminals: paymentTerminalsReducer,
  pos: posReducer,
  posIdentityRequest: posIdentityRequestReducer,
  posIdentityRequests: posIdentityRequestsReducer,
  posServer: posServerReducer,
  posServers: posServersReducer,
  posTerminal: posTerminalReducer,
  posTerminals: posTerminalsReducer,
  posTypes: posTypesReducer,
  posUpdate: posUpdateReducer,
  prepStation: prepStationReducer,
  prepStationItemTypes: prepStationItemTypesReducer,
  prepStations: prepStationsReducer,
  printer: printerReducer,
  printers: printersReducer,
  productMixReport: productMixReportReducer,
  qrCode: qrCodeReducer,
  onboardingQualityControlReports: onboardingQualityControlReportsReducer,
  receiptNote: receiptNote,
  receiptNotes: receiptNotes,
  receiptsReport: receiptsReportReducer,
  recommendedItems: recommendedItemsReducer,
  regenerate: regenerateReducer,
  regions: regionsReducer,
  region: regionReducer,
  relay: relayReducer,
  releaseRedisLock: releaseRedisLockReducer,
  revenueCenter: revenueCenterReducer,
  revenueCenterBlockedHours: revenueCenterBlockedHoursReducer,
  revenueCenterCredit: revenueCenterCreditReducer,
  revenueCenterDelivery: revenueCenterDeliveryReducer,
  revenueCenterEmail: revenueCenterEmailReducer,
  revenueCenterGratuity: revenueCenterGratuityReducer,
  revenueCenterOrderWindows: revenueCenterOrderWindowsReducer,
  revenueCenterPos: revenueCenterPosReducer,
  revenueCenterPosSettings: revenueCenterPosSettingsReducer,
  revenueCenters: revenueCentersReducer,
  revenueCenterServiceTypes: revenueCenterServiceTypesReducer,
  revenueCenterSurcharges: revenueCenterSurchargesReducer,
  revenueCenterUsers: revenueCenterUsersReducer,
  savedContent: savedContentReducer,
  savedContents: savedContentsReducer,
  searchUserLocations: searchUserLocationsReducer,
  scanner: scannerReducer,
  scanners: scannersReducer,
  segment: segmentReducer,
  segments: segmentsReducer,
  segmentRecipients: segmentRecipientsReducer,
  stores: storesReducer,
  store: storeReducer,
  storeAddress: storeAddressReducer,
  storeCredit: storeCreditReducer,
  storeDelivery: storeDeliveryReducer,
  storeEmail: storeEmailReducer,
  storeGratuity: storeGratuityReducer,
  storeGroups: storeGroupsReducer,
  storeGroup: storeGroupReducer,
  storeIntegration: storeIntegrationReducer,
  storeMenus: storeMenusReducer,
  storePos: storePosReducer,
  storePosSettings: storePosSettingsReducer,
  storeThrottle: storeThrottleReducer,
  stylesheets: stylesheetsReducer,
  surcharge: surchargeReducer,
  surcharges: surchargesReducer,
  tag: tagReducer,
  tags: tagsReducer,
  tax: taxReducer,
  taxes: taxesReducer,
  taxRevenueCenters: taxRevenueCentersReducer,
  tendersReport: tendersReportReducer,
  tendersSummaryReport: tendersSummaryReportReducer,
  tenderTypes: tenderTypesReducer,
  triggers: triggers,
  twilio: twilioReducer,
  uberDirect: uberDirectReducer,
  unchargedOrders: unchargedOrdersReducer,
  upcomingOrders: upcomingOrdersReducer,
  user: userReducer,
  userExperienceSettings: userExperienceSettingsReducer,
  userPermissions: userPermissionsReducer,
  userVpnConfig: userVpnConfigReducer,
  users: usersReducer,
  valutec: valutecReducer,
  waitTimeDelivery: waitTimeDeliveryReducer,
  waitTimePickup: waitTimePickupReducer,
  waitTimesDelivery: waitTimesDeliveryReducer,
  waitTimesPickup: waitTimesPickupReducer,
  website: websiteReducer,
  wanConfigs: wanConfigsReducer,
  wanConfig: wanConfigReducer
})

export default rootReducer
