import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { DistanceDeliveryTier, DistanceDeliveryTiers } from 'types'
import { makeQueryParams, RequestError, RequestStatus, sortBy } from 'utils'
import { showNotification } from './notification'
import { selectStoreDistanceDeliveryTiers } from './storeDistanceDeliveryTiers'

export interface DistanceDeliveryTiersState {
  data: DistanceDeliveryTiers
  links: Record<string, string>
  loading: RequestStatus
  error: RequestError
}

export interface DistanceDeliveryTiersAPI {
  data: DistanceDeliveryTiers
  links: {
    next: string
  }
}

export type DistanceDeliveryTiersParams = {
  cursor?: string
  limit?: number
  surchargeId?: number
  locationId?: number
  storeId?: number
}

const initialState: DistanceDeliveryTiersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const addTier = createAsyncThunk<
  void,
  { tier: DistanceDeliveryTier },
  { state: RootState; rejectValue: RequestError }
>(
  'distanceDeliveryTiers/addTier',
  async ({ tier }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `distance-delivery-tiers`
      await api?.request(endpoint, 'POST', tier)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteTier = createAsyncThunk<
  void,
  { id: number },
  { state: RootState; rejectValue: RequestError }
>(
  'distanceDeliveryTiers/deleteTier',
  async ({ id }, { getState, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `distance-delivery-tiers/${id}`
      await api?.request(endpoint, 'DELETE')
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const addTiers = createAsyncThunk<
  void,
  { data: DistanceDeliveryTiers },
  { state: RootState; rejectValue: RequestError }
>(
  'distanceDeliveryTiers/addTiers',
  async ({ data }, { dispatch, rejectWithValue }) => {
    try {
      const promises = data.map(tier => dispatch(addTier({ tier })))
      await Promise.all(promises)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteTiers = createAsyncThunk<
  void,
  { ids: number[] },
  { state: RootState; rejectValue: RequestError }
>(
  'distanceDeliveryTiers/deleteTiers',
  async ({ ids }, { dispatch, rejectWithValue }) => {
    try {
      const promises = ids.map(id => dispatch(deleteTier({ id })))
      await Promise.all(promises)
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const saveDistanceDeliveryTiers = createAsyncThunk<
  void,
  {
    data: DistanceDeliveryTiers
    surchargeId: number
    locationId?: number
    storeId?: number
  },
  { state: RootState; rejectValue: RequestError }
>(
  'distanceDeliveryTiers/saveDistanceDeliveryTiers',
  async (
    { data, surchargeId, locationId = 0, storeId = 0 },
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const isStoreLevel = Boolean(locationId || storeId)
      const select = isStoreLevel
        ? selectStoreDistanceDeliveryTiers
        : selectDistanceDeliveryTiers
      const tiers = select(getState()).data
      const ids = tiers.map(tier => tier.distance_delivery_tier_id)
      await dispatch(deleteTiers({ ids }))
      await dispatch(addTiers({ data }))
      dispatch(showNotification('Distance Delivery Tiers saved successfully'))
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchDistanceDeliveryTiers = createAsyncThunk<
  DistanceDeliveryTiersAPI,
  DistanceDeliveryTiersParams,
  { state: RootState; rejectValue: RequestError }
>(
  'distanceDeliveryTiers/fetchDistanceDeliveryTiers',
  async (params, { getState, rejectWithValue }) => {
    try {
      const { surchargeId, locationId = 0, storeId = 0, ...rest } = params
      const queryParams = rest ? makeQueryParams(rest) : ''
      const endpoint = `distance-delivery-tiers${queryParams}`
      const api = getState().authUser.api
      const res = (await api?.request(endpoint)) as DistanceDeliveryTiersAPI

      const filteredData =
        res.data.filter(
          tier =>
            tier.surcharge_id === surchargeId &&
            tier.store_id === storeId &&
            tier.location_id === locationId
        ) || null

      const data = sortBy(filteredData, 'min_distance')

      return { ...res, data }
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const distanceDeliveryTiersSlice = createSlice({
  name: 'distanceDeliveryTiers',
  initialState,
  reducers: {
    resetDistanceDeliveryTiers: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchDistanceDeliveryTiers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchDistanceDeliveryTiers.fulfilled,
      (state, { payload }) => {
        state.data = payload.data
        state.links = payload.links
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchDistanceDeliveryTiers.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetDistanceDeliveryTiers } = distanceDeliveryTiersSlice.actions

export const selectDistanceDeliveryTiers = (state: RootState) =>
  state.distanceDeliveryTiers

export default distanceDeliveryTiersSlice.reducer
