import {
  CampaignType,
  RadioGroupOptionInterface,
  TriggerEventType,
  CampaignContentType,
  CampaignStatus
} from 'types'

export const campaignTypeOptions: { value: CampaignType; text: string }[] = [
  { text: 'Single Send', value: 'SINGLE_SEND' },
  { text: 'Automated', value: 'RECURRING' }
]

export const campaignStatusOptions: { value: CampaignStatus; text: string }[] =
  [
    { text: 'Draft', value: 'DRAFT' },
    { text: 'Scheduled', value: 'SCHEDULED' },
    { text: 'Sent', value: 'SENT' },
    { text: 'Active', value: 'ACTIVE' },
    { text: 'Inactive', value: 'PAUSED' },
    { text: 'Failed', value: 'FAILED' }
  ]

export const campaignEventOptions: { value: TriggerEventType; text: string }[] =
  [
    { value: TriggerEventType.Birthday, text: 'Birthday' },

    { value: TriggerEventType.FirstLogin, text: 'Guest registered' },
    { value: TriggerEventType.Attrition, text: 'Last order date' },
    { value: TriggerEventType.OrderPlaced, text: 'Order placed' }
  ]

export const campaignScheduleTypeOptions: RadioGroupOptionInterface[] = [
  {
    value: 'send_now',
    text: 'Send Now',
    content: 'Send your campaign immediately'
  },
  {
    value: 'schedule',
    text: 'Schedule',
    content: 'Choose a future date and time to send your campaign'
  }
]

export const audienceSendToOptions = [
  { value: 'any', text: 'Include customers in any of these segments' },
  { value: 'all', text: 'Include customers in all of these segments' }
]

export const audienceDontSendToOptions = [
  { value: 'any', text: 'Exclude customers in any of these segments' },
  { value: 'all', text: 'Exclude customers in all of these segments' }
]

export const scheduleBeforeAfterOnOptions = [
  { value: '-1', text: 'Before the event' },
  { value: '0', text: 'On the event' },
  { value: '1', text: 'After the event' }
]

export const scheduleLimitMessagesOptions = [
  { value: 'no_limit', text: 'No Limit' },
  { value: 'limit_by_count', text: 'Limit by message count' },
  { value: 'limit_by_time', text: 'Limit by timeframe' }
]

export const scheduleLimitMessagesByTimeOptions = [
  { value: 'day', text: 'Day' },
  { value: 'week', text: 'Week' },
  { value: 'month', text: 'Month' }
]

export const scheduleActiveCampaignOptions = [
  { value: 'never', text: 'Not yet' },
  { value: 'active_now', text: 'Active now' },
  { value: 'schedule', text: 'Active later' }
]

export const savedContentTypeOptions: {
  text: string
  value: CampaignContentType
}[] = [
  { value: 'HTML', text: 'Email Template' },
  { value: 'ROW', text: 'Saved Row' },
  { value: 'TEXT', text: 'Merge Tag' },
  { value: 'LINK', text: 'Saved URL' }
]

export const campaignTimeZoneAbbreviations = [
  { value: 'US/Eastern', text: 'EST' },
  { value: 'US/Central', text: 'CST' },
  { value: 'US/Mountain', text: 'MST' },
  { value: 'US/Pacific', text: 'PST' }
]

export const campaignArchiveOptions: {
  value: string
  text: string
}[] = [
  { value: 'false', text: 'Active' },
  { value: 'true', text: 'Archived' },
  { value: 'none', text: 'All' }
]
