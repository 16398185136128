import { FormFieldType } from 'types'
import {
  channelTypeOptions,
  orderTypeOptions,
  discountTypeOptions,
  serviceTypeOptions,
  discountAuthTypeOptions,
  tenderTypeOptions
} from 'config'

const discountFields: FormFieldType[] = [
  {
    fieldType: 'Input',
    type: 'text',
    name: 'name',
    required: true,
    label: 'Name',
    error: 'Please add a unique name'
  },
  {
    fieldType: 'Select',
    name: 'discount_type',
    options: discountTypeOptions,
    required: false,
    label: 'Discount Type',
    error: 'Please add a description'
  },
  {
    fieldType: 'Input',
    name: 'amount',
    required: true,
    label: 'Amount',
    error: 'Please add an amount'
  },
  {
    fieldType: 'Input',
    type: 'number',
    name: 'days_valid',
    min: 1,
    max: 1024,
    nullable: true,
    required: false,
    label: 'Days Valid',
    parenthetical: '(optional limit on how long this discount is valid)',
    error: 'Please add days valid'
  },
  {
    fieldType: 'Input',
    type: 'number',
    name: 'max_redemptions_per',
    min: 0,
    required: true,
    label: 'Max Uses per Individual Customer',
    parenthetical: '(set to 0 for no limit)',
    error: 'Please add max uses per customer'
  },
  {
    fieldType: 'Input',
    type: 'number',
    name: 'max_redemptions',
    min: 0,
    required: true,
    label: 'Max Uses across All Customers',
    parenthetical: '(set to 0 for no limit)',
    error: 'Please add max uses'
  },
  {
    fieldType: 'Input',
    name: 'min_amount',
    required: false,
    label: 'Minimum Discount Amount',
    parenthetical: '(in dollars, set to 0.00 for no minimum)',
    error: 'Please add a minimum dollar amount'
  },
  {
    fieldType: 'Input',
    name: 'max_amount',
    required: false,
    label: 'Maximum Discount Amount',
    parenthetical: '(in dollars, set to 0.00 for no maximum)',
    error: 'Please add a maximum dollar amount'
  },
  {
    fieldType: 'Input',
    name: 'min_order_size',
    required: false,
    label: 'Minimum Order Size',
    parenthetical: '(in dollars, set to 0.00 for no minimum)',
    error: 'Please add a minimum order size'
  },
  {
    fieldType: 'Input',
    name: 'max_order_size',
    required: false,
    label: 'Maximum Order Size',
    parenthetical: '(in dollars, set to 0.00 for no maximum)',
    error: 'Please add a maximum order size'
  },
  {
    fieldType: 'Input',
    name: 'points_shop_cost',
    nullable: true,
    required: false,
    label: 'Points Shop Cost',
    parenthetical: '(in points to 2 decimal places or leave blank)',
    error: 'Please add a point shop cost'
  },
  {
    fieldType: 'Select',
    name: 'order_type',
    options: orderTypeOptions,
    nullable: true,
    required: false,
    label: 'Order Type',
    error: 'Please add a order type'
  },
  {
    fieldType: 'Select',
    name: 'service_type',
    options: serviceTypeOptions,
    nullable: true,
    required: false,
    label: 'Service Type',
    error: 'Please add a service type'
  },
  {
    fieldType: 'Select',
    name: 'channel_type',
    options: channelTypeOptions,
    nullable: true,
    required: false,
    label: 'Channel Type',
    error: 'Please add a service type'
  },
  {
    fieldType: 'Select',
    name: 'tender_type',
    //append All option on top
    options: [
      { value: 'none', text: 'All' },
      ...tenderTypeOptions.filter(
        option => option.value !== 'APPLE_PAY' && option.value !== 'GOOGLE_PAY'
      )
    ],
    nullable: true,
    required: false,
    label: 'Tender Type',
    error: 'Please add a service type for this promo code'
  },
  {
    fieldType: 'Input',
    type: 'date',
    name: 'start_date',
    required: false,
    label: 'Start Date',
    nullable: true,
    error: 'Please add start date'
  },
  {
    fieldType: 'Input',
    type: 'date',
    name: 'end_date',
    required: false,
    label: 'End Date',
    nullable: true,
    error: 'Please add end date'
  },
  {
    fieldType: 'Input',
    type: 'time',
    name: 'start_time',
    required: false,
    label: 'Start Time',
    nullable: true,
    error: 'Please add a valid time or remove values'
  },
  {
    fieldType: 'Input',
    type: 'time',
    name: 'end_time',
    required: false,
    label: 'End Time',
    nullable: true,
    error: 'Please add a valid time or remove values'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'title',
    required: true,
    label: 'Title',
    parenthetical: '(up to 256 characters)',
    error: 'Please add a title'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'internal_desc',
    required: true,
    label: 'Short Description',
    parenthetical: '(up to 256 characters)',
    error: 'Please add a short description'
  },
  {
    fieldType: 'TextArea',
    type: 'text',
    name: 'description',
    required: false,
    label: 'Description',
    parenthetical: '(up to 1024 characters)',
    error: 'Please add a short description'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'domain',
    required: false,
    label: 'Email Address Domain',
    parenthetical:
      '(enter an email address domain, such as somedomain.com, to limit usage)',
    error:
      'Please add a domain name for this promo code, do not include http part of URL'
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'pos_ext_id',
    required: false,
    label: 'Pos ID',
    error: 'Please enter a POS ID for this promo code'
  },
  {
    fieldType: 'Select',
    type: 'text',
    name: 'auth_type',
    options: discountAuthTypeOptions,
    required: false,
    label: 'Authorization Type',
    error: 'Please add a authorization type, do not include http part of URL'
  },
  {
    fieldType: 'Checkbox',
    label: 'Active',
    name: 'is_active'
  },
  {
    fieldType: 'Checkbox',
    label: 'Reusable',
    name: 'is_reusable_reward'
  },
  {
    fieldType: 'Checkbox',
    label: 'Taxed',
    name: 'is_taxed'
  },
  {
    fieldType: 'Checkbox',
    label: 'Before Surcharge',
    name: 'before_surcharge'
  },
  {
    fieldType: 'Input',
    type: 'number',
    name: 'per_order',
    min: 0,
    label: 'Total Rewards per Order',
    error: 'Please add total deals per order'
  },
  {
    fieldType: 'Checkbox',
    label: 'New Customers Only',
    name: 'requires_new'
  },
  {
    fieldType: 'Checkbox',
    label: 'Approved Email Addresses Only',
    name: 'requires_email'
  },
  {
    fieldType: 'Checkbox',
    label: 'Allow $0.00',
    name: 'allow_zero'
  },
  {
    fieldType: 'Checkbox',
    name: 'requires_approval',
    label: 'Requires Manager Approval'
  }
]

export const filterDiscountFields = (requiredFields: string[]) => {
  return discountFields
    .filter(field => requiredFields.includes(field.name))
    .sort((a, b) => {
      const indexA = requiredFields.indexOf(a.name)
      const indexB = requiredFields.indexOf(b.name)
      return indexA - indexB
    })
}

export const ensureRequiredFields = (data: any) => {
  const updatedData = { ...data }

  discountFields?.forEach((field: any) => {
    const { name, required, type } = field

    if (required) {
      const value = data[name]

      if (
        (type === 'text' &&
          (!value || (typeof value === 'string' && value.trim() === ''))) ||
        (type === 'number' && value === null)
      ) {
        updatedData[name] = null
      }
    }
  })
  return updatedData
}
