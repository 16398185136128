import {
  OrderGroupFilter,
  FormattedAddress,
  Section,
  SurchargeType,
  TenderType,
  Weekday,
  CardType,
  OrderType,
  ServiceType,
  OrderTypeOld,
  InitialReceiptStatus,
  OrderSource,
  ExternalAPI,
  PosTerminalPrint,
  DiscountType,
  PrepStationAssemblyType,
  AppDesignNavSection,
  AppVariablesSectionType,
  ReceiptStatus,
  WeekDay,
  LoginMethod,
  RevenueCenterPrinterType,
  RevenueCenterType,
  ConfigComponentType,
  PosScannerType,
  EditorColors,
  ConfigPageType
} from 'types'
import { arrayRange, minutesToTime } from 'utils'

// export const editorColors = {
//   background: '#fdfaf6',
//   headingPrimary: '#191919',
//   headingSecondary: '#191919',
//   bodyPrimary: '#2e3141',
//   bodySecondary: '#686d76',
//   borderPrimary: '#000000',
//   borderSecondary: '#aaaaaa'
// }

export const editorColors: EditorColors = {
  background: '#ffffff',
  headingPrimary: '#000000',
  headingSecondary: '#000000',
  bodyPrimary: '#000000',
  bodySecondary: '#000000',
  borderPrimary: '#000000',
  borderSecondary: '#000000',
  backgroundPrimary: '#000000',
  backgroundSecondary: '#999999'
}

export const editorDesignVariables: {
  value: AppVariablesSectionType
  text: string
}[] = [
  { value: 'colors', text: 'Colors' },
  { value: 'sizes', text: 'Sizes' }
]

export const editorDesignSections: {
  value: AppDesignNavSection
  text: string
}[] = [
  { value: 'typography', text: 'Typography' },
  { value: 'shadow', text: 'Shadows' },
  { value: 'background', text: 'Backgrounds' },
  { value: 'border', text: 'Borders' }
]

export const editorPageOptions: { value: ConfigPageType; text: string }[] = [
  { value: 'guest', text: 'Guest' },
  { value: 'account', text: 'Account' },
  { value: 'menu', text: 'Menu' },
  { value: 'category', text: 'Category' }
]

export const editorComponentOptions: {
  value: ConfigComponentType
  text: string
}[] = [
  { value: 'allergen', text: 'Allergen' },
  { value: 'applied', text: 'Applied' },
  { value: 'buttonLink', text: 'Button Link' },
  { value: 'categoryItem', text: 'Category Item' },
  { value: 'menuCategory', text: 'Menu Category' }
]

export const printerTypeOptions: {
  value: RevenueCenterPrinterType
  text: string
}[] = [
  { value: 'DESKTOP', text: 'Desktop Printer' },
  { value: 'RECEIPT', text: 'Receipt Printer' }
]

export const flexDirection = [
  { value: '', text: '--' },
  { value: 'row', text: 'Row' },
  { value: 'row-reverse', text: 'Row Reverse' },
  { value: 'column', text: 'Column' },
  { value: 'column-reverse', text: 'Column Reverse' }
]

export const justifyContent = [
  { value: '', text: '--' },
  { value: 'flex-start', text: 'Flex Start' },
  { value: 'center', text: 'Center' },
  { value: 'flex-end', text: 'Flex End' },
  { value: 'space-between', text: 'Space Between' },
  { value: 'space-around', text: 'Space Around' },
  { value: 'space-evenly', text: 'Space Evenly' }
]

export const weekdays: Weekday[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
]

export const tenderTypes: TenderType[] = [
  'CASH',
  'CREDIT',
  'HOUSE_ACCOUNT',
  'GIFT_CARD',
  'APPLE_PAY',
  'GOOGLE_PAY'
]

export const tenderTypeMap: Record<TenderType, string> = {
  CASH: 'Cash',
  COMP: 'Comp',
  CREDIT: 'Credit',
  HOUSE_ACCOUNT: 'House Account',
  GIFT_CARD: 'Gift Card',
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
  EXTERNAL: 'External'
}

export const cardTypeMap: Record<CardType, string> = {
  AMEX: 'Amex',
  VISA: 'Visa',
  MC: 'Mastercard',
  DISC: 'Discover',
  OTHER: 'Other'
}

export const revenueCenterType: Record<RevenueCenterType, string> = {
  OLO: 'OLO',
  CATERING: 'Catering',
  MERCH: 'Merch',
  POS: 'Kiosk',
  KIOSK: 'Kiosk'
}

export const orderTypeConvertMap: Record<OrderTypeOld, OrderType> = {
  MAIN_MENU: 'OLO',
  CATERING: 'CATERING',
  MERCH: 'MERCH'
}

export const externalAPIMap: Record<ExternalAPI, string> = {
  CHECKMATE_TOOS: 'ItsaCheckmate',
  CHOWLY_TOOS: 'Chowly',
  COMO: 'Como',
  DOORDASH: 'DoorDash',
  FIREBASE: 'Firebase',
  MAILCHIMP: 'MailChimp',
  NMI: 'NMI',
  ONFLEET: 'OnFleet',
  PUNCHH: 'Punchh',
  RELAY: 'Relay',
  SPARKFLY: 'Sparkfly',
  THANX: 'Thanx',
  TWILIO: 'Twilio',
  UBER_DIRECT: 'Uber Direct',
  VANTIV: 'Worldpay'
}

export const cardTypes = [
  'None',
  'Visa',
  'Mastercard',
  'Discover',
  'Amex',
  'Other'
]

export const cardTypeOptions: { value: CardType | 'none'; text: string }[] = [
  { value: 'none', text: 'All' },
  { value: 'AMEX', text: 'Amex' },
  { value: 'VISA', text: 'Visa' },
  { value: 'MC', text: 'Mastercard' },
  { value: 'DISC', text: 'Discover' },
  { value: 'OTHER', text: 'Other' }
]

export const donationTypeOptions = [
  { value: 'none', text: 'All' },
  { value: 'true', text: 'Yes' },
  { value: 'false', text: 'No' }
]

interface TenderTypeOption {
  value: TenderType
  text: string
}

export const tenderTypeOptions: TenderTypeOption[] = [
  { value: 'CASH', text: 'Cash' },
  { value: 'CREDIT', text: 'Credit' },
  { value: 'HOUSE_ACCOUNT', text: 'House Account' },
  { value: 'GIFT_CARD', text: 'Gift Card' },
  { value: 'APPLE_PAY', text: 'Apple Pay' },
  { value: 'GOOGLE_PAY', text: 'Google Pay' },
  { value: 'EXTERNAL', text: 'External' }
]

export const tenderTypeFilterOptions = [
  { value: 'none', text: 'All' },
  ...tenderTypeOptions
]

export const discountsMap: Record<DiscountType, string> = {
  DOLLAR: 'Dollar',
  PERCENTAGE: 'Percentage',
  LOYALTY: 'Loyalty'
}

export const surchargesMap: Record<SurchargeType, string> = {
  DOLLAR: 'Dollar',
  PERCENTAGE: 'Percentage',
  DISTANCE: 'Distance',
  TRAVEL_TIME: 'Travel Time'
}

const times15 = arrayRange(15, 1425, 15).map(minutes => ({
  value: minutes,
  text: minutesToTime(minutes)
}))

export const cutoffTimeOptions = [
  { value: 'none', text: 'No Cutoff' },
  ...times15
]

export const alldayTimeOptions = [...times15]

export const revenueCenterTypeOptions = [
  { value: 'OLO', text: 'OLO' },
  { value: 'CATERING', text: 'Catering' },
  { value: 'MERCH', text: 'Merch' },
  { value: 'POS', text: 'Kiosk' }
]

export const daysAheadOptions = [
  { value: 'none', text: 'Use global setting' },
  { value: 0, text: '0 days in advance' },
  { value: 1, text: '1 days in advance' },
  { value: 2, text: '2 days in advance' },
  { value: 3, text: '3 days in advance' },
  { value: 4, text: '4 days in advance' },
  { value: 5, text: '5 days in advance' },
  { value: 6, text: '6 days in advance' }
]

export const waitTimeRangeOptions = [
  { value: 0, text: 'None (display specific time)' },
  { value: 15, text: '15 minutes' },
  { value: 30, text: '30 minutes' }
]

export const allergenOptions = [
  { value: 'NONE', text: 'None' },
  { value: 'SHOW_HIDE', text: 'Show/Hide' },
  { value: 'HIGHLIGHT', text: 'Highlight' }
]

export const tagOptions = [
  { value: 'NONE', text: 'None' },
  { value: 'SHOW_HIDE', text: 'Show/Hide' },
  { value: 'HIGHLIGHT', text: 'Highlight' }
]

export const offlineIntervalOptions = [
  { value: 15, text: '15 minutes' },
  { value: 30, text: '30 minutes' },
  { value: 60, text: '60 minutes' }
]

export const largeOrderTimingOptions = [
  {
    value: 'DAY_OF',
    text: 'Send on day of order (at designated time in morning)'
  },
  { value: 'NOW', text: 'Send when order is submitted' }
]

export const temperatureOptions = [
  { value: 'HOT', text: 'Hot' },
  { value: 'COLD', text: 'Cold' }
]

export const itemShapeOptions = [
  { value: 'CIRCULAR', text: 'Circular' },
  { value: 'RECTANGULAR', text: 'Rectangular' }
]

export const timezoneOptions = [
  { value: 'US/Eastern', text: 'Eastern' },
  { value: 'US/Central', text: 'Central' },
  { value: 'US/Mountain', text: 'Mountain' },
  { value: 'US/Pacific', text: 'Pacific' }
]

export const prepStatusOptions = [
  { value: 'TODO', text: 'To Do (not yet started)' },
  { value: 'IN_PROGRESS', text: 'In Progress (cooking)' },
  { value: 'DONE', text: 'Done (prep finished)' },
  { value: 'COMPLETED', text: 'Completed (reviewed by Expo)' },
  { value: 'FULFILLED', text: 'Fulfilled (delivered to guest)' }
]

export const prepStatusOptionsSimple = [
  { value: 'TODO', text: 'To Do' },
  { value: 'IN_PROGRESS', text: 'In Progress' },
  { value: 'DONE', text: 'Done' },
  { value: 'COMPLETED', text: 'Completed' },
  { value: 'FULFILLED', text: 'Fulfilled' }
]

export const taxTypeOptions = [
  { value: 'DOLLAR', text: 'Dollar' },
  { value: 'PERCENTAGE', text: 'Percentage' }
]

export const prepStationAssemblyOptions: {
  value: PrepStationAssemblyType
  text: string
}[] = [
  { value: 'ASSEMBLY', text: 'Assembly' },
  { value: 'EXPO', text: 'Expo' }
]

export const posTerminalPrintReceiptOptions: {
  value: PosTerminalPrint
  text: string
}[] = [
  { value: 'NEVER', text: 'Never Print' },
  { value: 'PROMPT', text: 'Prompt User' },
  { value: 'ALWAYS', text: 'Always Print' }
]

export const posTerminalScannerOptions: {
  value: PosScannerType
  text: string
}[] = [
  { value: 'NONE', text: 'No Scanner' },
  { value: 'USB', text: 'USB Scanner' },
  { value: 'CAMERA', text: 'Device Camera' },
  { value: 'MORPHSTICK', text: 'Morph Stick' }
]

export const surchargeTypeOptions = [
  { value: 'DOLLAR', text: 'Dollar' },
  { value: 'PERCENTAGE', text: 'Percentage' },
  { value: 'DISTANCE', text: 'Distance' },
  { value: 'TRAVEL_TIME', text: 'Travel Time' }
]

export const surchargeTravelTypeOptions = [
  { value: 'DRIVING', text: 'Driving' },
  { value: 'WALKING', text: 'Walking' },
  { value: 'BICYCLING', text: 'Bicycling' },
  { value: 'TRANSIT', text: 'Public Transit' }
]

export const surchargeServiceTypesOptions = [
  { value: 'ALL', text: 'All Order Types' },
  { value: 'WALKIN', text: 'In-store Only' },
  { value: 'PICKUP', text: 'Pick-up Only' },
  { value: 'DELIVERY', text: 'Delivery Only' }
]

export const orderGroupBy: { value: OrderGroupFilter; text: string }[] = [
  { value: 'DATE', text: 'Date' },
  { value: 'STORE', text: 'Store' }
]

export const signatureThresholdOptions = [
  { value: null, text: 'None' },
  { value: '0.00', text: '$0.00 (all transactions)' },
  { value: '25.00', text: '$25.00' },
  { value: '50.00', text: '$50.00' }
]

export const gratuityServiceTypeOptions = [
  { value: 'NEITHER', text: 'Neither Pick-up nor Delivery' },
  { value: 'PICKUP', text: 'Pick-up Only' },
  { value: 'DELIVERY', text: 'Delivery Only' },
  { value: 'BOTH', text: 'Both Pick-up and Delivery' }
]

export const orderTypeOptions = [
  { value: 'none', text: 'All' },
  { value: 'MAIN_MENU', text: 'OLO' },
  { value: 'CATERING', text: 'Catering' },
  { value: 'MERCH', text: 'Merch' }
]

export const loyaltyOrderTypeOptions = [
  { value: 'ALL', text: 'All' },
  { value: 'MAIN_MENU', text: 'OLO' },
  { value: 'CATERING', text: 'Catering' },
  { value: 'MERCH', text: 'Merch' }
]

export const houseAccountOrderTypeOptions = [
  { value: 'BOTH_OLO_CATERING', text: 'All' },
  { value: 'OLO_ONLY', text: 'Main Menu' },
  { value: 'CATERING_ONLY', text: 'Catering' }
]

export const orderStatusOptions: {
  value: ReceiptStatus | 'none'
  text: string
}[] = [
  { value: 'none', text: 'All' },
  { value: 'PENDING', text: 'Pending' },
  { value: 'OPEN', text: 'Open' },
  { value: 'HELD', text: 'Held' },
  { value: 'CLOSED', text: 'Closed' },
  { value: 'VOID', text: 'Void' }
]

export const orderSourceOptions: {
  value: OrderSource | 'none'
  text: string
}[] = [
  { value: 'none', text: 'All' },
  { value: 'BRANDIBBLE', text: 'Checkmate Direct Ordering' },
  { value: 'EATSA', text: 'Brightloom' },
  { value: 'CHOWLY', text: 'Chowly' }
]

export const tenderStatusOptions = [
  { value: 'none', text: 'All' },
  { value: 'AUTHORIZED', text: 'Authorized' },
  { value: 'PAID', text: 'Paid' },
  { value: 'REFUNDED', text: 'Refunded' },
  { value: 'VOID', text: 'Void' },
  { value: 'FAILED', text: 'Failed' }
]

export const discountAuthTypeOptions = [
  { value: 'ACCOUNT', text: 'Customers with accounts' },
  { value: 'VERIFIED', text: 'Customers with verified accounts' }
]

export const discountTypeOptions = [
  { value: 'PERCENTAGE', text: 'Percentage' },
  { value: 'DOLLAR', text: 'Dollar' }
]

export const spendTypeOptions = [
  { value: 'POINTS', text: 'Points' },
  { value: 'DOLLARS', text: 'Spend (e.g. spend $50, get $5)' },
  {
    value: 'ORDERS',
    text: 'Frequency (i.e. number of visits or orders placed)'
  }
]

export const serviceTypeOptions = [
  { value: 'none', text: 'All' },
  { value: 'WALKIN', text: 'Dine-In' },
  { value: 'PICKUP', text: 'Pick-Up' },
  { value: 'DELIVERY', text: 'Delivery' }
  // { value: 'PORTAL', text: 'Portal' }
]

export const serviceTypeMap = serviceTypeOptions
  .filter(i => i.value !== 'none')
  .reduce(
    (obj, i) => ({ ...obj, [i.value]: i.text }),
    {} as Record<ServiceType, string>
  )

export const PlaceOrderTypeOptions: {
  value: OrderType
  text: string
}[] = [
  { value: 'OLO', text: 'OLO' },
  { value: 'CATERING', text: 'Catering' }
]

export const orderServiceTypeOptions: Array<{
  value: ServiceType
  text: string
}> = [
  { value: 'WALKIN', text: 'Walk-In' },
  { value: 'PICKUP', text: 'Pick-Up' },
  { value: 'DELIVERY', text: 'Delivery' }
]

export const channelTypeOptions = [
  { value: 'none', text: 'All' },
  { value: 'POS', text: 'Kiosk' },
  { value: 'ONLINE', text: 'Online' },
  { value: 'APP', text: 'App' },
  { value: 'PHONE', text: 'Phone' }
  // { value: 'KIOSK', text: 'Kiosk' }
  // { value: 'PORTAL', text: 'Portal' }
]

export const weekdayOptions: { value: WeekDay; text: string }[] = [
  { value: 'MONDAY', text: 'Monday' },
  { value: 'TUESDAY', text: 'Tuesday' },
  { value: 'WEDNESDAY', text: 'Wednesday' },
  { value: 'THURSDAY', text: 'Thursday' },
  { value: 'FRIDAY', text: 'Friday' },
  { value: 'SATURDAY', text: 'Saturday' },
  { value: 'SUNDAY', text: 'Sunday' }
]

export const orderNotifications = [
  { value: 'NONE', text: 'Neither Email nor SMS' },
  { value: 'EMAIL', text: 'Email Only' },
  { value: 'SMS', text: 'SMS Only' },
  { value: 'ALL', text: 'Both Email and SMS' }
]

export const posIntegrationOptions = [
  { value: 'NONE', text: 'None' },
  { value: 'CHECKMATE_TOOS', text: 'Checkmate' }
  // { value: 'CHOWLY_TOOS', text: 'Chowly' },
  // { value: 'OTTER', text: 'Otter' },
  // { value: 'CHECKMATE', text: 'Checkmate' },
  // { value: 'REVEL', text: 'Revel' },
  // { value: 'SALIDO', text: 'Salido' },
  // { value: 'GUSTO', text: 'Qu (formerly Gusto)' },
  // { value: 'RPOWER', text: 'RPower' },
  // { value: 'EATSA', text: 'Brightloom (formerly Eatsa)' },
  // { value: 'WEBHOOK', text: 'Webhook' }
]

export const posSendTimingOptions = [
  { value: 'PLACED', text: 'When placed by customer' },
  { value: 'FIRED', text: 'When time to make the order' },
  { value: 'CONFIRMED', text: 'When manually confirmed' },
  { value: 'NEVER', text: 'Never send orders to POS' }
]

export const deliveryIntegrationOptions = [
  { value: 'NONE', text: 'None' },
  { value: 'DOORDASH', text: 'Doordash' },
  { value: 'UBER_DIRECT', text: 'Uber Direct' },
  { value: 'RELAY', text: 'Relay' },
  { value: 'ONFLEET', text: 'Onfleet' }
]

export const deliveryServiceTypeOptions = [
  { value: 'PICKUP', text: 'Pick-Up' },
  { value: 'DELIVERY', text: 'Delivery' },
  { value: 'BOTH_PICKUP_DELIVERY', text: 'Both Pick-up and Delivery' }
]

export const apiScopeOptions = [
  { value: 'order-api', text: 'Order API' },
  { value: 'admin-api', text: 'Admin API' }
]

export const apiChannelOptions = [
  { value: 'APP', text: 'App' },
  { value: 'ONLINE', text: 'Online' },
  { value: 'KIOSK', text: 'Kiosk' }
]

export const checkoutSettingOptions = [
  // { value: 'NOT_DISPLAYED', text: 'Not Displayed' },
  { value: null, text: 'Not Displayed' },
  { value: 'DISPLAYED', text: 'Displayed' },
  { value: 'REQUIRED', text: 'Required' }
]

export const menuDisplayType = [
  { value: 'DEFAULT', text: 'Default' },
  { value: 'SCROLLABLE', text: 'Single Page, Scrollable' }
]

export const menuDisplayTypeApp = [
  ...menuDisplayType,
  { value: 'TABS', text: 'Tabs' }
]

export const categoryDisplayType = [
  { value: 'HORIZONTAL', text: 'Horizontal, Small Image' },
  { value: 'VERTICAL', text: 'Vertical, Large Image' }
]

export const builderType = [
  { value: 'DEFAULT', text: 'Default' },
  { value: 'SIDEBAR', text: 'Sidebar' },
  { value: 'PAGE', text: 'Page (Content Sidebar)' },
  { value: 'PAGE_FULL', text: 'Page (Full Width)' }
]

export const menuItemQuickAdd = [
  { value: 'SHOW', text: 'Show Buttons' },
  { value: 'MINIMAL', text: 'Add Only' },
  { value: 'HIDE', text: 'Hide Buttons' }
]

export const menuItemQuickAddColor = [
  { value: 'primary', text: 'Primary' },
  { value: 'secondary', text: 'Secondary' },
  { value: 'cart', text: 'Cart' },
  { value: 'light', text: 'Light' }
]

export const menuItemContentLayout = [
  { value: 'PRICE_RIGHT', text: 'Price Opposite Name' },
  { value: 'PRICE_BELOW', text: 'Price Below Name' },
  { value: 'PRICE_LAST', text: 'Price Below Description' }
]

export const menuItemDescriptionType = [
  { value: 'SHOW', text: 'Show full description' },
  { value: 'SHORT', text: 'Show short description' },
  { value: 'HIDE', text: 'Hide description' }
]

export const sizeModifierDisplayType = [
  { value: 'BUTTONS', text: 'Buttons' },
  { value: 'ACCORDION', text: 'Accordion' }
]

export const modifierDisplayType = [
  { value: 'DEFAULT', text: 'Default (Horizontal layout)' },
  { value: 'CARDS', text: 'Cards (3 per row, 4 or more on desktop)' }
]

export const userRoleOptions = [
  { value: 'ADMIN', text: 'Admin' },
  { value: 'CATERING_MANAGER', text: 'Catering Manager' },
  { value: 'MANAGER', text: 'Manager' },
  { value: 'CASHIER', text: 'Cashier' },
  { value: 'EMPLOYEE', text: 'Employee' }
]

export const statusFilterOptions = [
  { value: 'none', text: 'All' },
  { value: 'ACTIVE', text: 'Active' },
  { value: 'INACTIVE', text: 'Inactive' }
]

export const thresholdsFilterOptions = [
  { value: 'AMOUNT', text: 'Amount' },
  { value: 'NAME', text: 'Name' }
]

export const downloadStatusOptions = [
  { value: 'QUEUED', text: 'Queued' },
  { value: 'RUNNING', text: 'Running' },
  { value: 'SUCCEEDED', text: 'Succeeded' },
  { value: 'FAILED', text: 'Failed' }
]

export const horizontalAlignmentOptions = [
  { value: 'LEFT', text: 'Left' },
  { value: 'CENTER', text: 'Center' },
  { value: 'RIGHT', text: 'Right' }
]

export const verticalAlignmentOptions = [
  { value: 'TOP', text: 'Top' },
  { value: 'CENTER', text: 'Center' },
  { value: 'BOTTOM', text: 'Bottom' }
]

export const transitionTypeOptions = [
  { value: 'SLIDE', text: 'Slide' },
  { value: 'FADE', text: 'Fade' }
]

export const resultsPerPageOptions = [
  { value: 10, text: '10' },
  { value: 25, text: '25' },
  { value: 50, text: '50' },
  { value: 100, text: '100' },
  { value: 200, text: '200' },
  { value: 500, text: '500' },
  { value: 1000, text: '1000' }
]

export const userAccessSections: { value: Section; text: string }[] = [
  { value: 'ORDERS', text: 'Orders' },
  { value: 'CUSTOMERS', text: 'Customers / Loyalty' },
  { value: 'REPORTING', text: 'Reporting' },
  { value: 'LOCATIONS', text: 'Stores' },
  { value: 'MENUS', text: 'Menus' },
  { value: 'WEBSITE', text: 'Website / Pages / Design' },
  { value: 'SETTINGS', text: 'Settings' },
  { value: 'USERS', text: 'Users' },
  { value: 'LABOR', text: 'Employees' }
]

export const confirmationEmailType = [
  { value: 'INITIAL', text: 'Initial' },
  { value: 'PENDING', text: 'Pending' },
  { value: 'RESEND', text: 'Resend' },
  { value: 'FINAL', text: 'Final' },
  { value: 'CANCEL', text: 'Cancel' },
  { value: 'REFUND', text: 'Refund' },
  { value: 'POS', text: 'In-store' }
]

export const emailDesignAlignment = [
  { value: 'LEFT', text: 'Left' },
  { value: 'CENTER', text: 'Center' },
  { value: 'RIGHT', text: 'Right' }
]

export const emailDesignTextTransform = [
  { value: 'NONE', text: 'None' },
  { value: 'UPPERCASE', text: 'Uppercase' },
  { value: 'LOWERCASE', text: 'Lowercase' },
  { value: 'CAPITALIZE', text: 'Capitalize' }
]

export const emailDesignFontWeights = [
  { value: 'NORMAL', text: 'Normal' },
  { value: 'BOLD', text: 'Bold' },
  { value: 'BOLDER', text: 'Bolder' },
  { value: 'LIGHTER', text: 'Lighter' }
]

export const emailDesignFonts = [
  { value: 'ARIAL', text: 'Arial' },
  { value: 'ARIAL_BLACK', text: 'Arial Black' },
  { value: 'ARIAL_NARROW', text: 'Arial Narrow' },
  { value: 'COMIC_SANS', text: 'Comic Sans' },
  { value: 'COURIER_NEW', text: 'Courier New' },
  { value: 'GENEVA', text: 'Geneva' },
  { value: 'GEORGIA', text: 'Georgia' },
  { value: 'HELVETICA', text: 'Helvetica' },
  { value: 'LUCIDA_CONSOLE', text: 'Lucida Console' },
  { value: 'LUCIDA_SANS', text: 'Lucida Sans' },
  { value: 'PALANTINO', text: 'Palantino' },
  { value: 'TAHOMA', text: 'Tahoma' },
  { value: 'TIMES_NEW_ROMAN', text: 'Times New Roman' },
  { value: 'TREBUCHET_MS', text: 'Trebuchet MS' },
  { value: 'VERDANA', text: 'Verdana' }
]

export const imageBackgroundSize = [
  { value: 'cover', text: 'Cover' },
  { value: 'contain', text: 'Contain' },
  { value: '100% auto', text: '100% auto' },
  { value: 'auto 100%', text: 'auto 100%' }
]

export const fontWeight = [
  { value: '', text: '--' },
  { value: 100, text: '100 - Thin' },
  { value: 200, text: '200 - Extra Light' },
  { value: 300, text: '300 - Light' },
  { value: 400, text: '400 - Normal' },
  { value: 500, text: '500 - Medium' },
  { value: 600, text: '600 - SemiBold' },
  { value: 700, text: '700 - Bold' },
  { value: 800, text: '800 - Extra Bold' },
  { value: 900, text: '900 - Black' }
]

export const fontStyle = [
  { value: '', text: '--' },
  { value: 'normal', text: 'normal' },
  { value: 'italic', text: 'italic' },
  { value: 'oblique', text: 'oblique' },
  { value: 'initial', text: 'initial' },
  { value: 'inherit', text: 'inherit' }
]

export const fontSmoothing = [
  { value: '', text: '--' },
  { value: 'none', text: 'none' },
  { value: 'auto', text: 'auto' },
  { value: 'antialiased', text: 'antialiased' },
  { value: 'subpixel-antialiased', text: 'subpixel-antialiased' }
]

export const textAlign = [
  { value: '', text: '--' },
  { value: 'left', text: 'left' },
  { value: 'center', text: 'center' },
  { value: 'right', text: 'right' },
  { value: 'justify', text: 'justify' }
]

export const textDecoration = [
  { value: '', text: '--' },
  { value: 'none', text: 'None' },
  { value: 'underline', text: 'Underline' },
  { value: 'line-through', text: 'Line Through' },
  { value: 'overline', text: 'Overline' },
  { value: 'inherit', text: 'Inherit' }
]

export const textTransform = [
  { value: '', text: '--' },
  { value: 'none', text: 'none' },
  { value: 'uppercase', text: 'uppercase' },
  { value: 'lowercase', text: 'lowercase' },
  { value: 'capitalize', text: 'capitalize' }
]

export const borderStyle = [
  { value: '', text: '--' },
  { value: 'none', text: 'None' },
  { value: 'solid', text: 'Solid' },
  { value: 'dotted', text: 'Dotted' },
  { value: 'dashed', text: 'Dashed' },
  { value: 'double', text: 'Double' },
  { value: 'groove', text: 'Groove' },
  { value: 'ridge', text: 'Ridge' },
  { value: 'inset', text: 'Inset' },
  { value: 'outset', text: 'Outset' }
]

export const position = [
  { value: 'static', text: 'Static' },
  { value: 'relative', text: 'Relative' },
  { value: 'absolute', text: 'Absolute' },
  { value: 'sticky', text: 'Sticky' }
]

export const justifyContentVertical = [
  { value: 'flex-start', text: 'Top' },
  { value: 'center', text: 'Center' },
  { value: 'flex-end', text: 'Bottom' }
]

export const lineType = [
  { value: 'none', text: 'None' },
  { value: 'underline', text: 'Underline' },
  { value: 'overline', text: 'Overline' }
]

export const imagePosition = [
  { value: 'TOP', text: 'Top' },
  { value: 'LEFT', text: 'Left' },
  { value: 'RIGHT', text: 'Right' },
  { value: 'BOTTOM', text: 'Bottom' },
  { value: 'NONE', text: 'Hidden' }
]

export const modifiersLayout = [
  { value: 'DEFAULT', text: 'Default' },
  { value: 'CARDS', text: 'Cards' },
  { value: 'SQUARES', text: 'Squares' },
  { value: 'SIMPLE', text: 'Simple' }
]

export const categoriesDisplayType = [
  { value: 'LIST', text: 'List' },
  { value: 'CARDS', text: 'Cards' },
  { value: 'SQUARES', text: 'Squares' }
]

export const perPageFilterOptions = [
  { value: 10, text: '10' },
  { value: 25, text: '25' },
  { value: 50, text: '50' },
  { value: 100, text: '100' },
  { value: 200, text: '200' },
  { value: 500, text: '500' },
  { value: 1000, text: '1000' }
]

export const saleTypeOptions = [
  { value: 'none', text: 'All' },
  { value: 'SALE', text: 'Sale' },
  { value: 'REFUND', text: 'Refund' }
]

export const tagsDisplayType = [
  { value: 'TEXT', text: 'Text' },
  { value: 'IMAGE', text: 'Image' },
  { value: 'BOTH', text: 'Both' },
  { value: 'HIDDEN', text: 'Hidden' }
]

export const tplsOptions = [
  { value: 'none', text: 'None' },
  { value: 'COMO', text: 'Como' },
  { value: 'PUNCHH', text: 'Punchh' },
  { value: 'SPARKFLY', text: 'Sparkfly' }
  // { value: 'THANX', text: 'Thanx' }
]

export const prepTypeOptions = [
  { value: 'EAT_HERE', text: 'Dine In' },
  { value: 'TAKE_OUT', text: 'Take Out' }
]

export const pageTimeoutOptions = [
  { value: 0, text: 'No Timeout' },
  { value: 60, text: '1 Minute' },
  { value: 120, text: '2 Minutes' },
  { value: 180, text: '3 Minutes' },
  { value: 240, text: '4 Minutes' },
  { value: 300, text: '5 Minutes' },
  { value: 360, text: '6 Minutes' },
  { value: 420, text: '7 Minutes' },
  { value: 480, text: '8 Minutes' },
  { value: 540, text: '9 Minutes' },
  { value: 600, text: '10 Minutes' }
]

export const integrationStatusOptions = [
  { value: 'LIVE', text: 'Live' },
  { value: 'OFF', text: 'Inactive' }
]

export const paymentsIntegrationOptions = [
  { value: 'NMI', text: 'NMI' },
  { value: 'VANTIV', text: 'Worldpay' },
  { value: 'FREEDOMPAY', text: 'FreedomPay' }
]

export const storePaymentsIntegrationOptions = [
  { value: 'NONE', text: 'None' },
  { value: 'NMI', text: 'NMI' },
  { value: 'VANTIV', text: 'Worldpay' },
  { value: 'FREEDOMPAY', text: 'FreedomPay' }
  // { value: 'CARDCONNECT', text: 'CardConnect' }
]

export const initialRceiptStatusOptions: {
  value: InitialReceiptStatus
  text: string
}[] = [
  { value: 'OPEN', text: 'Open' },
  { value: 'PENDING', text: 'Pending Approval' }
]

export const loginMethodOptions: {
  value: LoginMethod
  text: string
}[] = [
  { value: 'SMS_OTP', text: 'Phone + OTP' },
  { value: 'EMAIL_OTP', text: 'Email + OTP' },
  { value: 'EMAIL_OR_SMS_OTP', text: 'Phone or Email + OTP' },
  { value: 'EMAIL_AND_SMS_PW_OTP', text: 'Email & Password with OTP Backup' },
  { value: 'EMAIL_PW', text: 'Email & Password' }
]

export const stateKey = 'state'

const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
]

const PROVINCES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT'
]

export const states = STATES.map(state => ({ value: state, text: state }))

export const provinces = PROVINCES.map(province => ({
  value: province,
  text: province
}))

export const statesProvinces = [
  ...states,
  { value: '', text: '', disabled: true },
  ...provinces
]

export const formattedAddressFields: Array<keyof FormattedAddress> = [
  'street',
  'city',
  'state',
  'postal_code'
]

export const RADIUS_MILES = 3959 // radius of the earth in miles
export const RADIUS_KM = 6371 // radius of the earth in kilometers

export const paidStatusFilterOptions = [
  { value: 'none', text: 'All' },
  { value: 'PAID', text: 'Paid' },
  { value: 'UNPAID', text: 'Unpaid' }
]

export const autoCloseOptions = [
  { value: null, text: 'No, do not automatically close catering orders' },
  { value: '22:00:00', text: '10:00 PM' },
  { value: '22:15:00', text: '10:15 PM' },
  { value: '22:30:00', text: '10:30 PM' },
  { value: '22:45:00', text: '10:45 PM' },
  { value: '23:00:00', text: '11:00 PM' },
  { value: '23:15:00', text: '11:15 PM' },
  { value: '23:30:00', text: '11:30 PM' }
]

export const netSalesOptions = [
  {
    value: 'SUBTOTAL',
    text: 'Subtotal'
  },
  {
    value: 'SUBTOTAL,DISCOUNT',
    text: 'Subtotal + Discount'
  },
  {
    value: 'SUBTOTAL,SURCHARGE',
    text: 'Subtotal + Surcharge'
  },
  {
    value: 'SUBTOTAL,SURCHARGE,DISCOUNT',
    text: 'Subtotal + Surcharge + Discount'
  }
]

export const notificationTypeOptions = [
  { value: 'IN_ASSEMBLY_QUEUE', text: 'In Assembly Queue' },
  { value: 'IN_PROGRESS', text: 'In Progress' },
  { value: 'DELAYED', text: 'Delayed' },
  { value: 'DONE', text: 'Done' },
  { value: 'COMPLETED', text: 'Completed' },
  { value: 'FULFILLED', text: 'Fulfilled' }
]

export const isClosedOptions = [
  { value: 'ALL', text: 'All' },
  { value: 'false', text: 'Open' },
  { value: 'true', text: 'Closed' }
]

export const isOutpostOptions = [
  { value: 'ALL', text: 'All' },
  { value: 'true', text: 'Outposts Only' },
  { value: 'false', text: 'Exclude Outposts' }
]

export const allOption: {
  value: 'none'
  text: string
} = { value: 'none', text: 'All' }

export const brandTypes = [
  { value: 'Independent', text: 'Independent' },
  { value: 'Corporate', text: 'Corporate' },
  { value: 'Franchise', text: 'Sub Brand' }
]

export const enterpriseLevels = [
  {
    value: 1,
    text: 'Superbrand'
  },
  {
    value: 2,
    text: 'Enterprise 1'
  },
  {
    value: 3,
    text: 'Enterprise 2'
  },
  {
    value: 4,
    text: 'Enterprise 3'
  },
  {
    value: 5,
    text: 'Enterprise 4'
  },
  {
    value: 6,
    text: 'SMB'
  }
]

export const archiveStatusOptions = [
  { value: 'showAll', text: 'Show All' },
  { value: 'active', text: 'Active' },
  { value: 'archived', text: 'Archived' }
]

export const exportOnboardingAllBrands = 'export_onboarding_all_brands'

export const exportOnboardingAllLocations = 'export_onboarding_all_locations'

export const exportOnboardingOrderingPlatforms =
  'export_onboarding_all_onboarding_platforms'

export const exportOnboardingMenuMapping =
  'export_onboarding_all_menu_mapping_requests'

export const orderRatingsOptions = [
  { value: 'none', text: 'none selected' },
  { value: 1, text: '1 stars' },
  { value: 2, text: '2 stars' },
  { value: 3, text: '3 stars' },
  { value: 4, text: '4 stars' },
  { value: 5, text: '5 stars' }
]

const celeryTasks = [
  'export_csv_task',
  'regenerate_menus_task',
  'regenerate_brand_menus_task',
  'send_background_email_task',
  'send_order_notification_task',
  'sync_chowly_menus_for_location_task',
  'sync_checkmate_menus_for_store_task',
  'expire_inactive_users_task',
  'execute_triggers_task',
  'refresh_olo_settings_task',
  'outstanding_catering_orders_task',
  'flush_temp_expirations_task',
  'clean_time_punches_task',
  'sync_ctuit_task',
  'sync_pos_menus_task',
  'regenerate_menus_task',
  'retry_uncharged_orders_task',
  'delete_sync_events_task',
  'give_birthday_rewards_task',
  'give_scheduled_rewards_task',
  'create_ready_olo_orders_sync_events_task',
  'synchronize_cloud_ids_task',
  'send_pos_orders_task',
  'send_crm_orders_task',
  'update_order_throttling_task',
  'update_triggers_task',
  'notify_unconfirmed_orders_task',
  'send_order_rating_emails_task',
  'detect_offline_pos_task',
  'close_catering_orders_task',
  'generate_business_dates_task'
]

export const redisLockOptions = celeryTasks.map(task => ({
  value: `lock_${task}`,
  text: task
}))

export const menuTypeOptions = [
  { value: 'SCROLLABLE', text: 'Scrollable' },
  { value: 'PAGES', text: 'Pages' }
]

export const navigationPageTypeOptions = [
  { value: 'TOP', text: 'Top' },
  { value: 'LEFT', text: 'Left' },
  { value: 'RIGHT', text: 'Right' }
]

export const savedContentFilterOptions: {
  value: string
  text: string
}[] = [
  { value: 'all', text: 'All' },
  { value: 'active', text: 'Active' },
  { value: 'archived', text: 'Archived' },
  { value: 'brand', text: 'Brand' },
  { value: 'system', text: 'System' }
]

export const interfaceNumberOptions = [{ value: 1, text: '2' }]
