import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BaseDeliveryFees } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'

export interface BaseDeliveryFeesState {
  data: BaseDeliveryFees | null
  loading: RequestStatus
  error: RequestError
}

export interface BaseDeliveryFeesAPI {
  data: BaseDeliveryFees
  links: {
    next: string
  }
}

export type BaseDeliveryFeesParams = {
  cursor?: string
  limit?: number
  surchargeId?: number
  locationId?: number
  storeId?: number
}

const initialState: BaseDeliveryFeesState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const fetchBaseDeliveryFees = createAsyncThunk<
  BaseDeliveryFees | null,
  BaseDeliveryFeesParams,
  { state: RootState; rejectValue: RequestError }
>(
  'baseDeliveryFees/fetchBaseDeliveryFees',
  async (params, { getState, rejectWithValue }) => {
    try {
      const { surchargeId, ...rest } = params
      const queryParams = rest ? makeQueryParams(rest) : ''
      const endpoint = `v2-delivery-settings${queryParams}`
      const api = getState().authUser.api
      const res = (await api?.request(endpoint)) as BaseDeliveryFeesAPI
      return res.data
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const baseDeliveryFeesSlice = createSlice({
  name: 'baseDeliveryFees',
  initialState,
  reducers: {
    resetBaseDeliveryFees: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchBaseDeliveryFees.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchBaseDeliveryFees.fulfilled, (state, { payload }) => {
      state.data = payload
      state.loading = RequestStatus.Idle
      state.error = null
    })
    builder.addCase(fetchBaseDeliveryFees.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetBaseDeliveryFees } = baseDeliveryFeesSlice.actions

export const selectBaseDeliveryFees = (state: RootState) =>
  state.baseDeliveryFees

export default baseDeliveryFeesSlice.reducer
