import { CampaignChannel, CampaignContentType } from 'types'

export const getSavedContentTypeLabel = (
  contentType?: string,
  channel?: CampaignChannel,
  plural?: boolean
) => {
  if (contentType === 'HTML' && channel === 'WEB') {
    return plural ? 'Web Page Templates' : 'Web Page Template'
  } else if (contentType === 'HTML') {
    return plural ? 'Email Templates' : 'Email Template'
  } else if (contentType === 'ROW') {
    return plural ? 'Saved Rows' : 'Saved Row'
  } else if (contentType === 'TEXT') {
    return plural ? 'Merge Tags' : 'Merge Tag'
  } else if (contentType === 'LINK') {
    return plural ? 'Saved URLs' : 'Saved URL'
  } else {
    return ''
  }
}

export const getContentTypePath = (contentType?: string) => {
  switch (contentType) {
    case 'HTML':
      return 'email-templates'
    case 'ROW':
      return 'saved-rows'
    case 'TEXT':
      return 'merge-tags'
    case 'LINK':
      return 'saved-urls'
    default:
      return ''
  }
}

export const getContentType = (
  contentTypePath?: string
): CampaignContentType | undefined => {
  switch (contentTypePath) {
    case 'email-templates':
      return 'HTML'
    case 'saved-rows':
      return 'ROW'
    case 'merge-tags':
      return 'TEXT'
    case 'saved-urls':
      return 'LINK'
    default:
      return undefined
  }
}

export const getPageTextSnippet = (pageHtml: string) => {
  const regexp = /(?:<p[^>]*?>\s*)(.*?)(?:\s*<\/p>)/gm
  const paragraphs = []
  let match = regexp.exec(pageHtml)
  while (match) {
    paragraphs.push(match[1])
    match = regexp.exec(pageHtml)
  }
  return paragraphs.join('<br>')
}
