import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { RequestError, RequestStatus } from 'utils'
import { WanConfig } from 'types'
import { showNotification } from './notification'
import { NavigateFunction } from 'react-router-dom'
import { fetchWanConfigs } from './wanConfigs'

export interface WanConfigState {
  loading: RequestStatus
  data: WanConfig | null
  error: RequestError
}

const initialState: WanConfigState = {
  loading: RequestStatus.Idle,
  data: null,
  error: null
}

export const createWanConfig = createAsyncThunk<
  WanConfig,
  { data: WanConfig; navigate?: NavigateFunction },
  { state: RootState; rejectValue: RequestError }
>(
  'wanConfig/createWanConfig',
  async ({ data /*, navigate*/ }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        'pos-v2/wan-configs',
        'POST',
        data
      )) as WanConfig
      dispatch(showNotification('WAN config successfully created!'))
      dispatch(fetchWanConfigs())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const fetchWanConfig = createAsyncThunk<
  WanConfig,
  { wanConfigId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'wanConfig/fetchWanConfig',
  async ({ wanConfigId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pos-v2/wan-configs/${wanConfigId}`
      )) as WanConfig
      return resp || null
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const updateWanConfig = createAsyncThunk<
  WanConfig,
  { wanConfigId: number; data: WanConfig },
  { state: RootState; rejectValue: RequestError }
>(
  'wanConfig/updateWanConfig',
  async ({ wanConfigId, data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const resp = (await api?.request(
        `pos-v2/wan-configs/${wanConfigId}`,
        'PUT',
        data
      )) as WanConfig
      dispatch(showNotification('WAN config successfully updated!'))
      dispatch(fetchWanConfigs())
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const deleteWanConfig = createAsyncThunk<
  void,
  { wanConfigId: number },
  { state: RootState; rejectValue: RequestError }
>(
  'wanConfig/deleteWanConfig',
  async ({ wanConfigId }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      await api?.request(`pos-v2/wan-configs/${wanConfigId}`, 'DELETE')
      dispatch(showNotification('WAN config successfully deleted!'))
      dispatch(fetchWanConfigs())
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const wanConfigSlice = createSlice({
  name: 'wanConfig',
  initialState,
  reducers: {
    resetWanConfig: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(createWanConfig.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(createWanConfig.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(createWanConfig.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(updateWanConfig.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(updateWanConfig.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(updateWanConfig.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(fetchWanConfig.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(fetchWanConfig.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload || initialState.data
      state.error = null
    })
    builder.addCase(fetchWanConfig.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
    builder.addCase(deleteWanConfig.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(deleteWanConfig.fulfilled, state => {
      state.loading = RequestStatus.Idle
      state.error = null
      state.data = null
    })
    builder.addCase(deleteWanConfig.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetWanConfig } = wanConfigSlice.actions

export const selectWanConfig = (state: RootState) => state.wanConfig

export default wanConfigSlice.reducer
