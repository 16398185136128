import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Form, Input } from 'components'
import { Customer } from 'types'
import { RequestStatus, omit } from 'utils'
import { addCustomer, updateCustomer, selectCustomer } from 'slices/customer'
import { useEffect, useState } from 'react'
import CustomerBirthdateInput from './CustomerBirthdate'

const CustomerForm = ({
  customerId,
  children,
  callback
}: {
  customerId: string | number
  children?: React.ReactNode
  callback?: (data: Customer) => void
}) => {
  const dispatch = useAppDispatch()
  const [submitted, setSubmitted] = useState(false)
  const { data, error, loading } = useAppSelector(selectCustomer)
  const isLoading = loading === RequestStatus.Pending

  const errors = error?.params as Record<string, string>

  const [showDate, setShowDate] = useState<boolean>(true)

  const formData = data
    ? omit<Customer, 'customer_id'>(data, 'customer_id')
    : customerEmpty

  const [vals, setVals] = useState(formData || customerEmpty)
  const [birthDate, setBirthDate] = useState<string | undefined>(
    formData?.birth_date || undefined
  )

  const submit = (values: Customer) => {
    setSubmitted(true)

    let augmented = {
      ...values,
      ...vals,
      ...(birthDate && { birth_date: birthDate })
    }

    delete augmented.blocked_cards
    delete augmented.blocked_emails
    delete augmented.blocked_phones
    delete augmented.created
    delete augmented.customer_addresses
    delete augmented.customer_notification_preferences
    delete augmented.is_notification_set
    delete augmented.is_verified
    delete augmented.last_ordered
    delete augmented.last_ordered_business_date
    delete augmented.orders
    delete augmented.updated
    delete augmented.loyalty_programs
    delete augmented.notes
    if (data) {
      dispatch(updateCustomer({ data: augmented, customerId }))
    } else {
      dispatch(
        addCustomer({
          data: augmented
        })
      )
    }
  }

  useEffect(() => {
    if (customerId === 'new') {
      setBirthDate('')
      setVals(customerEmpty)
    }
  }, [customerId])

  useEffect(() => {
    if (customerId !== 'new' && data && !submitted) {
      setShowDate(false)
      const updatedFormData =
        omit<Customer, 'customer_id'>(data, 'customer_id') || customerEmpty
      setVals(updatedFormData)
      setBirthDate(updatedFormData.birth_date || '')
    }

    if (submitted && !isLoading) {
      setSubmitted(false)
      if (customerId !== 'new') {
        setShowDate(false)
      }
      if (!error && data && callback) {
        callback(data)
      }
    }
  }, [customerId, data, submitted, isLoading, error, callback])

  return (
    <Form
      fields={[]}
      data={formData || customerEmpty}
      error={error}
      isLoading={isLoading}
      submit={submit}
      extraFields={() => {
        const handleInput =
          (name: string) => (evt: React.ChangeEvent<HTMLInputElement>) => {
            const value = evt.target.value
            setVals({ ...vals, [name]: value })
          }

        return (
          <>
            <Input
              fieldType="Input"
              label="First Name"
              name="first_name"
              type="text"
              value={vals.first_name || ''}
              onChange={handleInput('first_name')}
              error={errors?.['$.first_name']}
            />
            <Input
              fieldType="Input"
              label="Last Name"
              name="last_name"
              type="text"
              value={vals.last_name || ''}
              onChange={handleInput('last_name')}
              error={errors?.['$.last_name']}
            />
            <CustomerBirthdateInput
              date={birthDate}
              showDate={showDate}
              customerId={customerId}
              setShowDate={setShowDate}
              setBirthDate={setBirthDate}
            />
            <Input
              fieldType="Input"
              label="Email"
              name="sender_email_address"
              type="email"
              required={true}
              value={vals.email || ''}
              onChange={handleInput('email')}
              error={errors?.['$.email'] && 'Please enter an email'}
              autoComplete="off"
            />
            <Input
              fieldType="Input"
              label="Password"
              name="password"
              type="password"
              required={true}
              value={vals.password || ''}
              onChange={handleInput('password')}
              error={errors?.['$.password'] && 'Please enter password'}
              autoComplete="off"
            />
            <Input
              fieldType="Input"
              label="Phone Number"
              name="phone"
              type="text"
              value={vals.phone || ''}
              onChange={handleInput('phone')}
              error={errors?.['$.phone']}
            />
            <Input
              fieldType="Input"
              label="Company"
              name="company"
              type="text"
              value={vals.company || ''}
              onChange={handleInput('company')}
              error={errors?.['$.company']}
            />
          </>
        )
      }}
    >
      {children}
    </Form>
  )
}

const customerEmpty = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  phone: '',
  company: '',
  birth_date: undefined
}

export default CustomerForm
