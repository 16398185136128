import styled from '@emotion/styled'
import { Input } from 'components'
import { InputView as BaseInputView } from './Forms/Input/Input.styled'
import Label from './Forms/Label'

type DateInputProps = {
  customerId: string | number
  showDate: boolean
  setShowDate: React.Dispatch<React.SetStateAction<boolean>>
  setBirthDate: React.Dispatch<React.SetStateAction<string | undefined>>
  date?: string
}

const Container = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const RevealText = styled.p<{ showDate: boolean }>`
  position: absolute;
  right: ${props => (props.showDate ? '4rem' : '1.5rem')};
  top: ${props => (props.showDate ? '53%' : '48%')};
  transform: translateY(-50%);
  background: none;
  border: none;
  color: ${props => props.theme.colors.text.primary};
  cursor: pointer;
  font-size: 1.3rem;
`

const DateInputView = styled(BaseInputView)`
  text-align: left;
  color: ${props => props.theme.colors.text.primary};

  &:focus {
    color: ${props => props.theme.colors.text.secondary};
    border-color: ${props => props.theme.colors.text.primary};
  }
`

const CustomerBirthdateInput = ({
  customerId,
  showDate,
  setShowDate,
  setBirthDate,
  date
}: DateInputProps) => {
  const [, month = 'MM', day = 'DD'] = date?.split('-') ?? []

  const handleToggle = () => setShowDate(prevShowDate => !prevShowDate)

  const revealToggle = (
    <RevealText onClick={handleToggle} showDate={showDate || false}>
      {showDate ? 'Hide' : 'Reveal'}
    </RevealText>
  )

  return (
    <>
      {showDate ? (
        <Container>
          <Input
            fieldType="Input"
            type="date"
            name="birth_date"
            value={date}
            required={false}
            label="Birth Date"
            parenthetical="(DD/MM/YYYY)"
            nullable={false}
            onChange={evt => setBirthDate(evt.target.value)}
          />
          {customerId !== 'new' && revealToggle}
        </Container>
      ) : (
        <Label
          htmlFor={'birth_date'}
          label={'Birth Date'}
          parenthetical={'(DD/MM/****)'}
          required={false}
        >
          <Container>
            <DateInputView
              name="birth_date"
              type="text"
              value={`${day}/${month}/****`}
              disabled={true}
            />
            {revealToggle}
          </Container>
        </Label>
      )}
    </>
  )
}

export default CustomerBirthdateInput
