import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FormFieldType, ModalWindow, WanConfig as WanConfigType } from 'types'
import { Form } from 'components'
import { ModalHeader } from 'components/Modal'
import { interfaceNumberOptions } from 'config'
import { RequestStatus } from 'utils'
import { closeModal } from 'slices/modal'
import {
  selectWanConfig,
  createWanConfig,
  resetWanConfig,
  updateWanConfig
} from 'slices/wanConfig'
import { fetchWanConfigs } from 'slices/wanConfigs'

const WanConfig = ({
  args,
  modalWindow
}: {
  args: { data: WanConfigType }
  modalWindow: ModalWindow
}) => {
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectWanConfig)
  const isLoading = loading === RequestStatus.Pending
  const [submitted, setSubmitted] = useState(false)
  const { data } = args

  const submit = async (values: WanConfigType) => {
    const { wan_config_id, ...rest } = values
    if (rest.pos_server_id) {
      if (wan_config_id) {
        dispatch(updateWanConfig({ wanConfigId: wan_config_id, data: rest }))
      } else {
        dispatch(createWanConfig({ data: rest }))
      }
      setSubmitted(true)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetWanConfig())
    }
  }, [dispatch])

  useEffect(() => {
    if (submitted && !error && !isLoading) {
      dispatch(fetchWanConfigs({ pos_server_id: data.pos_server_id }))
      dispatch(closeModal())
    }
  }, [dispatch, submitted, error, data.pos_server_id, isLoading])

  return (
    <div>
      <ModalHeader
        title="Add New Wan Config"
        subtitle="Please fill in all of the fields below"
      />
      <Form
        fields={wanConfigFields}
        data={data}
        error={error}
        isLoading={isLoading}
        submit={submit}
        scrollWindow={modalWindow}
      />
    </div>
  )
}

const wanConfigFields: FormFieldType[] = [
  {
    fieldType: 'Select',
    type: 'text',
    name: 'interface_number',
    options: interfaceNumberOptions,
    label: 'Port',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'ipv4_address',
    label: 'IPv4 Address',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'ipv4_netmask',
    label: 'IPv4 Netmask',
    required: true
  },
  {
    fieldType: 'Input',
    type: 'text',
    name: 'ipv4_gateway',
    label: 'IPv4 Gateway',
    required: true
  }
]

export default WanConfig
