import {
  AddBlocked,
  AdjustNotesInternal,
  AdjustTaxExempt,
  AdjustTip,
  ApiDetail,
  ArchiveBrand,
  ArchiveLocation,
  CampaignArchive,
  CampaignContentEmail,
  CampaignContentTestEmail,
  CampaignContentWeb,
  CampaignDetails,
  CampaignPause,
  CampaignReview,
  CampaignUnscheduling,
  CheckmateItemModal,
  CopyModifiers,
  Customer,
  CustomerAddress,
  CustomerSearch,
  DeleteBrand,
  DeleteCampaignConfirmation,
  DeleteChannelContent,
  DeleteSegmentConfirmation,
  DraftCampaignConfirmation,
  EditBrand,
  EditLocation,
  EditSpecialist,
  GiftCardBatch,
  IacmLogin,
  ItemAllergen,
  ItemLoyaltyBonus,
  ItemLoyaltyTier,
  ItemModifierGroup,
  ItemSearch,
  ItemTag,
  KdsTerminalPrepStation,
  Login,
  MenuItem,
  MenuItemModifierGroupEdit,
  Note,
  OnboardingQualityCheckOPStatus,
  OnboardingWelcome,
  OrderAddress,
  OrderCancel,
  OrderCartErrors,
  OrderCreditCard,
  OrderCreditCardNew,
  OrderDiscount,
  OrderEdit,
  OrderEditing,
  OrderGiftCard,
  OrderGiftCardNew,
  OrderHouseAccount,
  OrderMgmtCancel,
  OrderNotification,
  OrderOrderType,
  OrderPrepStatus,
  OrderPromoCode,
  OrderRequestedAt,
  OrderRevenueCenter,
  OrderServiceType,
  OrderSurcharge,
  OrderSubmitting,
  PageAnnouncement,
  PaymentConfirmation,
  PrepStationItemType,
  PreviewCategory,
  PreviewItem,
  PreviewModifierGroup,
  PromoCodeAddEmail,
  RevenueCenterBlockedHour,
  RevenueCenterOrderWindow,
  RevenueCenterServiceType,
  RevenueCenterSurcharge,
  ReviewPublishMenuError,
  SavedContentArchive,
  SavedContentDelete,
  SavedContentDetails,
  SavedEmailTemplateSettings,
  SavedRowSettings,
  SavedTextSnippetSettings,
  SegmentArchive,
  SegmentName,
  TemplateChange,
  TestLong,
  UserSearch,
  Warning,
  Working,
  WanConfig
} from 'components/Modals'
import { ModalWindow } from 'types'

interface ModalTypeProps {
  type: string | null
  modalWindow: ModalWindow
  args?: any // TODO: use a generic for this
}

const ModalType = ({ type, modalWindow, args = null }: ModalTypeProps) => {
  switch (type) {
    case 'addBlocked':
      return <AddBlocked args={args} modalWindow={modalWindow} />
    case 'addressSelect':
      return <OrderAddress args={args} modalWindow={modalWindow} />
    case 'adjustNotesInternal':
      return <AdjustNotesInternal args={args} modalWindow={modalWindow} />
    case 'adjustTaxExempt':
      return <AdjustTaxExempt args={args} modalWindow={modalWindow} />
    case 'adjustTip':
      return <AdjustTip args={args} modalWindow={modalWindow} />
    case 'apiDetail':
      return <ApiDetail args={args} modalWindow={modalWindow} />
    case 'archiveBrand':
      return <ArchiveBrand args={args} modalWindow={modalWindow} />
    case 'archiveLocation':
      return <ArchiveLocation args={args} modalWindow={modalWindow} />
    case 'savedContentArchive':
      return <SavedContentArchive args={args} modalWindow={modalWindow} />
    case 'campaignArchive':
      return <CampaignArchive args={args} modalWindow={modalWindow} />
    case 'campaignContentEmail':
      return <CampaignContentEmail args={args} modalWindow={modalWindow} />
    case 'campaignContentTestEmail':
      return <CampaignContentTestEmail args={args} modalWindow={modalWindow} />
    case 'campaignContentWeb':
      return <CampaignContentWeb args={args} modalWindow={modalWindow} />
    case 'campaignDetails':
      return <CampaignDetails args={args} modalWindow={modalWindow} />
    case 'campaignPause':
      return <CampaignPause args={args} />
    case 'campaignReview':
      return <CampaignReview args={args} modalWindow={modalWindow} />
    case 'campaignUnscheduling':
      return <CampaignUnscheduling args={args} />
    case 'checkmateItem':
      return <CheckmateItemModal args={args} />
    case 'copyModifiers':
      return <CopyModifiers args={args} modalWindow={modalWindow} />
    case 'customer':
      return <Customer args={args} modalWindow={modalWindow} />
    case 'customerAddress':
      return <CustomerAddress args={args} modalWindow={modalWindow} />
    case 'customerSearch':
      return <CustomerSearch args={args} modalWindow={modalWindow} />
    case 'deleteBrand':
      return <DeleteBrand />
    case 'deleteCampaignConfirmation':
      return (
        <DeleteCampaignConfirmation args={args} modalWindow={modalWindow} />
      )
    case 'deleteChannelContent':
      return <DeleteChannelContent args={args} modalWindow={modalWindow} />
    case 'deleteSegmentConfirmation':
      return <DeleteSegmentConfirmation args={args} modalWindow={modalWindow} />
    case 'draftCampaignConfirmation':
      return <DraftCampaignConfirmation args={args} modalWindow={modalWindow} />
    case 'editBrand':
      return <EditBrand args={args} modalWindow={modalWindow} />
    case 'editLocation':
      return <EditLocation args={args} modalWindow={modalWindow} />
    case 'editSpecialist':
      return <EditSpecialist args={args} modalWindow={modalWindow} />
    case 'giftCardBatch':
      return <GiftCardBatch args={args} modalWindow={modalWindow} />
    case 'iacmLogin':
      return <IacmLogin />
    case 'itemAllergen':
      return <ItemAllergen args={args} modalWindow={modalWindow} />
    case 'itemLoyaltyBonus':
      return <ItemLoyaltyBonus args={args} modalWindow={modalWindow} />
    case 'itemLoyaltyTier':
      return <ItemLoyaltyTier args={args} modalWindow={modalWindow} />
    case 'itemModifierGroup':
      return <ItemModifierGroup args={args} modalWindow={modalWindow} />
    case 'editItemModifierGroup':
      return <MenuItemModifierGroupEdit args={args} modalWindow={modalWindow} />
    case 'itemSearch':
      return <ItemSearch args={args} modalWindow={modalWindow} />
    case 'itemTag':
      return <ItemTag args={args} modalWindow={modalWindow} />
    case 'kdsTerminalPrepStation':
      return <KdsTerminalPrepStation args={args} modalWindow={modalWindow} />
    case 'longTestModal':
      return <TestLong modalWindow={modalWindow} />
    case 'note':
      return <Note args={args} modalWindow={modalWindow} />
    case 'onboardingQualityCheckOPStatus':
      return (
        <OnboardingQualityCheckOPStatus args={args} modalWindow={modalWindow} />
      )
    case 'onboardingWelcome':
      return <OnboardingWelcome />
    case 'orderCancel':
      return <OrderCancel />
    case 'orderCartErrors':
      return <OrderCartErrors args={args} modalWindow={modalWindow} />
    case 'orderCreditCard':
      return <OrderCreditCard args={args} modalWindow={modalWindow} />
    case 'orderCreditCardNew':
      return <OrderCreditCardNew modalWindow={modalWindow} />
    case 'orderDiscount':
      return <OrderDiscount />
    case 'orderEdit':
      return <OrderEdit args={args} />
    case 'orderEditing':
      return <OrderEditing />
    case 'orderGiftCard':
      return <OrderGiftCard args={args} modalWindow={modalWindow} />
    case 'orderGiftCardNew':
      return <OrderGiftCardNew args={args} modalWindow={modalWindow} />
    case 'orderHouseAccount':
      return <OrderHouseAccount args={args} modalWindow={modalWindow} />
    case 'orderMenuItem':
      return <MenuItem args={args} modalWindow={modalWindow} />
    case 'orderMgmtCancel':
      return <OrderMgmtCancel args={args} />
    case 'orderNotification':
      return <OrderNotification args={args} modalWindow={modalWindow} />
    case 'orderOrderType':
      return <OrderOrderType args={args} />
    case 'orderPrepStatus':
      return <OrderPrepStatus args={args} modalWindow={modalWindow} />
    case 'orderPromoCode':
      return <OrderPromoCode />
    case 'orderRequestedAt':
      return <OrderRequestedAt args={args} modalWindow={modalWindow} />
    case 'orderRevenueCenter':
      return <OrderRevenueCenter args={args} modalWindow={modalWindow} />
    case 'orderServiceType':
      return <OrderServiceType args={args} modalWindow={modalWindow} />
    case 'orderSubmitting':
      return <OrderSubmitting />
    case 'orderSurcharge':
      return <OrderSurcharge args={args} modalWindow={modalWindow} />
    case 'pageAnnouncement':
      return <PageAnnouncement args={args} modalWindow={modalWindow} />
    case 'prepStationItemType':
      return <PrepStationItemType args={args} modalWindow={modalWindow} />
    case 'paymentConfirmation':
      return <PaymentConfirmation args={args} modalWindow={modalWindow} />
    case 'previewCategory':
      return <PreviewCategory args={args} modalWindow={modalWindow} />
    case 'previewItem':
      return <PreviewItem args={args} modalWindow={modalWindow} />
    case 'previewModifierGroup':
      return <PreviewModifierGroup args={args} modalWindow={modalWindow} />
    case 'promoCodeAddEmail':
      return <PromoCodeAddEmail args={args} modalWindow={modalWindow} />
    case 'refreshSession':
      return <Login />
    case 'revenueCenterBlockedHour':
      return <RevenueCenterBlockedHour args={args} modalWindow={modalWindow} />
    case 'revenueCenterOrderWindow':
      return <RevenueCenterOrderWindow args={args} modalWindow={modalWindow} />
    case 'revenueCenterServiceType':
      return <RevenueCenterServiceType args={args} modalWindow={modalWindow} />
    case 'revenueCenterSurcharge':
      return <RevenueCenterSurcharge args={args} modalWindow={modalWindow} />
    case 'reviewPublishMenuError':
      return <ReviewPublishMenuError args={args} modalWindow={modalWindow} />
    case 'savedContentDetails':
      return <SavedContentDetails args={args} modalWindow={modalWindow} />
    case 'savedEmailTemplateSettings':
      return (
        <SavedEmailTemplateSettings args={args} modalWindow={modalWindow} />
      )
    case 'savedRowSettings':
      return <SavedRowSettings args={args} modalWindow={modalWindow} />
    case 'savedTextSnippetSettings':
      return <SavedTextSnippetSettings args={args} modalWindow={modalWindow} />
    case 'segmentArchive':
      return <SegmentArchive args={args} modalWindow={modalWindow} />
    case 'segmentDetails':
      return <SegmentName args={args} modalWindow={modalWindow} />
    case 'savedContentDelete':
      return <SavedContentDelete args={args} modalWindow={modalWindow} />
    case 'templateChange':
      return <TemplateChange args={args} modalWindow={modalWindow} />
    case 'userSearch':
      return <UserSearch args={args} modalWindow={modalWindow} />
    case 'warning':
      return <Warning args={args} modalWindow={modalWindow} />
    case 'working':
      return <Working args={args} />
    case 'wanConfig':
      return <WanConfig args={args} modalWindow={modalWindow} />
    default:
      return null
  }
}

export default ModalType
