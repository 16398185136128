import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'
import { BaseDeliveryFee, BaseDeliveryFees } from 'types'
import { makeQueryParams, RequestError, RequestStatus } from 'utils'
import { showNotification } from './notification'

export interface BaseDeliveryFeesState {
  data: BaseDeliveryFee | null
  loading: RequestStatus
  error: RequestError
}

export interface BaseDeliveryFeesAPI {
  data: BaseDeliveryFees
  links: {
    next: string
  }
}

export type BaseDeliveryFeesParams = {
  cursor?: string
  limit?: number
  surchargeId?: number
  locationId?: number
  storeId?: number
}

const initialState: BaseDeliveryFeesState = {
  data: null,
  loading: RequestStatus.Idle,
  error: null
}

export const updateStoreBaseDeliveryFee = createAsyncThunk<
  BaseDeliveryFee,
  { data: BaseDeliveryFee; id: number },
  { state: RootState; rejectValue: RequestError }
>(
  'storeBaseDeliveryFees/updateStoreBaseDeliveryFee',
  async ({ data, id }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `v2-delivery-settings/${id}`
      const resp = (await api?.request(
        endpoint,
        'PUT',
        data
      )) as BaseDeliveryFee
      dispatch(showNotification('Base Delivery Fee updated successfully'))
      dispatch(
        fetchStoreBaseDeliveryFee({
          surchargeId: data.surcharge_id,
          storeId: data.store_id,
          locationId: data.location_id
        })
      )
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const addStoreBaseDeliveryFee = createAsyncThunk<
  BaseDeliveryFee,
  { data: BaseDeliveryFee },
  { state: RootState; rejectValue: RequestError }
>(
  'storeBaseDeliveryFee/addStoreBaseDeliveryFee',
  async ({ data }, { getState, dispatch, rejectWithValue }) => {
    try {
      const api = getState().authUser.api
      const endpoint = `v2-delivery-settings`
      const resp = (await api?.request(
        endpoint,
        'POST',
        data
      )) as BaseDeliveryFee
      dispatch(showNotification('Base Delivery Fee added successfully'))
      dispatch(
        fetchStoreBaseDeliveryFee({
          surchargeId: data.surcharge_id,
          storeId: data.store_id,
          locationId: data.location_id
        })
      )
      return resp
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)
export const fetchStoreBaseDeliveryFee = createAsyncThunk<
  BaseDeliveryFee | null,
  BaseDeliveryFeesParams,
  { state: RootState; rejectValue: RequestError }
>(
  'storeBaseDeliveryFees/fetchStoreBaseDeliveryFee',
  async (params, { getState, rejectWithValue }) => {
    try {
      const { surchargeId, storeId = 0, locationId = 0, ...rest } = params
      const queryParams = rest ? makeQueryParams(rest) : ''
      const endpoint = `v2-delivery-settings${queryParams}`
      const api = getState().authUser.api
      const res = (await api?.request(endpoint)) as BaseDeliveryFeesAPI

      if (!storeId || !locationId) {
        return res.data.find(fee => fee.surcharge_id === surchargeId) || null
      }

      return (
        res.data.find(
          fee =>
            fee.surcharge_id === surchargeId &&
            fee.store_id === storeId &&
            fee.location_id === locationId
        ) || null
      )
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeBaseDeliveryFeesSlice = createSlice({
  name: 'storeBaseDeliveryFees',
  initialState,
  reducers: {
    resetStoreBaseDeliveryFees: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchStoreBaseDeliveryFee.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchStoreBaseDeliveryFee.fulfilled,
      (state, { payload }) => {
        state.data = payload
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchStoreBaseDeliveryFee.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(updateStoreBaseDeliveryFee.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      updateStoreBaseDeliveryFee.fulfilled,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.data = payload
        state.error = null
      }
    )
    builder.addCase(
      updateStoreBaseDeliveryFee.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
    builder.addCase(addStoreBaseDeliveryFee.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(addStoreBaseDeliveryFee.fulfilled, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.data = payload
      state.error = null
    })
    builder.addCase(addStoreBaseDeliveryFee.rejected, (state, { payload }) => {
      state.loading = RequestStatus.Idle
      state.error = payload
    })
  }
})

export const { resetStoreBaseDeliveryFees } = storeBaseDeliveryFeesSlice.actions

export const selectStoreBaseDeliveryFee = (state: RootState) =>
  state.storeBaseDeliveryFees

export default storeBaseDeliveryFeesSlice.reducer
