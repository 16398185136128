import styled from '@emotion/styled'
import { ThemeColor, ThemeFontSizes } from 'types'

export const TableWrapper = styled.div<{ noMinHeight?: boolean }>`
  width: 100%;
  // overflow: hidden;
  min-height: ${props => (props.noMinHeight ? '0rem;' : '32rem')};
`

export const TableContainer = styled.div`
  width: 100%;
  padding: ${props => props.theme.layout.desktop.padding};
  // margin: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    border-top: 0.1rem solid ${props => props.theme.colors.border.primary};
  }
`

export const TableView = styled.table<{
  headBg?: keyof ThemeColor
  size?: keyof ThemeFontSizes
}>`
  label: table;
  position: relative;
  width: 100%;
  // background-color: ${props => props.theme.colors.background.primary};

  td,
  th {
    text-align: center;
    padding: ${props =>
      props.size === 'small' ? '1.2rem 1rem' : '1.5rem 1rem'};
    text-transform: none;

    &:first-of-type {
      text-align: left;
      padding-left: 2rem;
      max-width: 20rem;

      button {
        text-align: left;
      }
    }

    a:hover,
    a:active,
    a:focus {
      color: ${props => props.theme.colors.accent.primary};
    }
  }

  thead {
    // position: sticky;
    // top: 0;
    // z-index: 5;
    // background-color: ${props => props.theme.colors.background.primary};
    border-bottom: ${props => props.theme.layout.desktop.border} solid
      ${props => props.theme.colors.border.primary};
  }

  th {
    font-size: ${props => props.theme.sizes.small};
  }

  td {
    font-size: ${props => props.theme.sizes[props.size || 'medium']};
  }

  tr {
    border-bottom: 0.1rem solid ${props => props.theme.colors.border.primary};
  }

  thead tr {
    border: 0;
  }

  tr.header-row {
    td {
      letter-spacing: 0.01em;
      font-weight: 650;
      font-variation-settings: 'wght' 650;
      padding-top: 3rem;
    }
  }

  tr.error-row {
    background-color: ${props => props.theme.colors.error.background};

    td {
      color: ${props => props.theme.colors.background.primary};

      a {
        color: ${props => props.theme.colors.background.primary};

        &:hover {
          color: ${props => props.theme.colors.text.primary};
        }
      }
    }
  }

  th.th-image,
  td.td-image {
    width: 6rem;
    padding: 0.8rem 0;
  }

  th.th-image + th,
  td.td-image + td {
    text-align: left;
    padding-left: 3rem;
    max-width: 20rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    &:not(.skip-mobile-view, .table-view-totals) {
      tbody {
        width: 100%;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        gap: 1.5rem;
      }

      thead {
        display: none;
      }

      tr {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.6rem 1.2rem;
        border: 0.1rem solid ${props => props.theme.colors.border.primary};
        border-bottom: 0.1rem solid
          ${props => props.theme.colors.border.primary};
        background-color: ${props => props.theme.colors.background.secondary};
      }

      tr.header-row {
        td {
          padding: 0.6rem 1.2rem;
        }
      }

      tr.error-row {
        background-color: ${props => props.theme.colors.error.background};
      }

      td {
        flex-grow: 1;

        .table-header-tablet-view {
          margin-right: 0.3rem;
        }
      }

      td,
      td:first-of-type {
        text-align: left;
        padding: 0.5rem 0;
        font-size: ${props => props.theme.sizes.small};
      }

      td.td-image {
        display: none;
        padding-left: 0;
      }

      th.th-image + th,
      td.td-image + td {
        padding-left: 0;
      }

      td > .quick-links-view {
        a {
          width: 4.4rem;
        }
      }
    }
  }
`

export const TableHeaderTabletView = styled.span`
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: none;
  }
`

export const TableSelect = styled.div`
  min-width: 20rem;

  label {
    margin: 0;
  }

  select {
    padding: 0.8rem 1rem;
    font-size: ${props => props.theme.sizes.small};
  }
`

export const TableCheckbox = styled.div`
  && label {
    margin: 0;
    justify-content: center;
  }

  && label span {
    font-size: 1.3rem;
    letter-spacing: 0.01em;
    font-weight: 650;
    font-variation-settings: 'wght' 650;
  }
`

export const TableButton = styled.span`
  display: block;
  margin: -0.5rem 0;
`

export const TableActions = styled.span`
  margin: -0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const TableTotalsView = styled.div`
  width: 100%;
  overflow: hidden;
  margin: 3rem 0;

  table tbody tr {
    border: 0.1rem solid ${props => props.theme.colors.border.primary};
    background-color: ${props => props.theme.colors.background.secondary};
  }
`

export const TableHeaderSortableView = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.accent.primary};
  }
`

export const TableHeaderSortableIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;
`

export const TableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`
