import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  DistanceDeliveryTiersAPI,
  DistanceDeliveryTiersParams,
  DistanceDeliveryTiersState
} from './distanceDeliveryTiers'
import { RootState } from 'app/store'
import { makeQueryParams, RequestError, RequestStatus, sortBy } from 'utils'

const initialState: DistanceDeliveryTiersState = {
  data: [],
  links: {},
  loading: RequestStatus.Idle,
  error: null
}

export const fetchStoreDistanceDeliveryTiers = createAsyncThunk<
  DistanceDeliveryTiersAPI,
  DistanceDeliveryTiersParams,
  { state: RootState; rejectValue: RequestError }
>(
  'storeDistanceDeliveryTiers/fetchStoreDistanceDeliveryTiers',
  async (params, { getState, rejectWithValue }) => {
    try {
      const { surchargeId, locationId = 0, storeId = 0, ...rest } = params
      const queryParams = rest ? makeQueryParams(rest) : ''
      const endpoint = `distance-delivery-tiers${queryParams}`
      const api = getState().authUser.api
      const res = (await api?.request(endpoint)) as DistanceDeliveryTiersAPI

      const filteredData =
        res.data.filter(
          tier =>
            tier.surcharge_id === surchargeId &&
            tier.store_id === storeId &&
            tier.location_id === locationId
        ) || null

      const data = sortBy(filteredData, 'min_distance')

      return { ...res, data }
    } catch (err) {
      return rejectWithValue(err as RequestError)
    }
  }
)

export const storeDistanceDeliveryTiersSlice = createSlice({
  name: 'storeDistanceDeliveryTiers',
  initialState,
  reducers: {
    resetStoreDistanceDeliveryTiers: () => initialState
  },
  extraReducers: builder => {
    builder.addCase(fetchStoreDistanceDeliveryTiers.pending, state => {
      state.loading = RequestStatus.Pending
    })
    builder.addCase(
      fetchStoreDistanceDeliveryTiers.fulfilled,
      (state, { payload }) => {
        state.data = payload.data
        state.links = payload.links
        state.loading = RequestStatus.Idle
        state.error = null
      }
    )
    builder.addCase(
      fetchStoreDistanceDeliveryTiers.rejected,
      (state, { payload }) => {
        state.loading = RequestStatus.Idle
        state.error = payload
      }
    )
  }
})

export const { resetStoreDistanceDeliveryTiers } =
  storeDistanceDeliveryTiersSlice.actions

export const selectStoreDistanceDeliveryTiers = (state: RootState) =>
  state.storeDistanceDeliveryTiers

export default storeDistanceDeliveryTiersSlice.reducer
